import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { SupportAccessViewModel } from '@features/models/support-access.model';
import { Result } from '@shared/models/result.model';
import { ENDPOINTS } from '@shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class SupportAccessService {
  constructor(private http: HttpClient) {}

  get(id: string) {
    return this.http.get<Result<SupportAccessViewModel | undefined>>(
      `${environment.apis.linkroom}${ENDPOINTS.supportAccess}/${id}`
    );
  }
}
