import { Constants } from "./constants";
import { createMicrophoneAudioTrack, createCameraVideoTrack, createShareTrack } from "./shared.js";


export let agoraLibraryPath;
export let agoraExtensionVirtualBackgroundPath;
let agoraExtesionVirtualBackground;

let client;
let shareClient;

let logLevel = 4;
let agoraClientUID;

// LIST
var mics = [];
var playbacks = [];
var cams = [];
var remoteUsers = {};

var localTracks = { videoTrack: null, audioTrack: null }
var currentMic = undefined;
var currentCam = undefined;
var localSCreenTrack = null;

//SETTINGS
var localTracksSettings = { videoTrack: null, audioTrack: null }
var currentMicSettings = undefined;
var currentCamSettings = undefined;
let volumeAnimation;
var percentage = 0;

var camerasAvailable = false;
var microphonesAvailable = false;

var experienceReady = false;

var selectedMicIDLocal = undefined;
var selectedCamIDLocal = undefined;

var isLocalTrackspublished = false;

//virtual background
let denoiser = null;
let processorSettings = null;
let processorSettingsEnabled = false;
let processor = null;
let processorEnabled = false;

export const EVENT = {
    USER_PUBLISHED: 'user-published',
    USER_UNPUBLISHED: 'user-unpublished',
    USER_VOLUMEINDICATOR: 'volume-indicator',
    USER_SHARE_PUBLISHED: 'user-share-published',
    USER_SHARE_UNPUBLISHED: 'user-share-unpublished',
    USER_JOINED_CHANNEL: 'user-joined-channel',
    USER_LEAVE_CHANNEL: 'user-leave-channel',
    VOLUME_WAVE_SETTINGS: 'volume-wave',
    STOP_SCREEN_SHARE: 'stop-screen',

    //network stats WIP rdf
    NETWORK_QUALITY: 'network-quality',
    CONNECTION_STATE_CHANGE: 'connection-state-change',
    LASTMILE_QUALITY: 'lastmile-quality',
    LASTMILE_PROBE_RESULT: 'lastmile-probe-result'
}

let events = {};

export var on = (_event, listener) => {
    if (!events[_event]) {
        events[_event] = [];
    }
    events[_event].push(listener);
}

export var off = (_event, listener) => {
    if (events[_event]) {
        events[_event] = events[_event].filter((existingListener) => {
            return existingListener !== listener;
        });
    }
}

let emit = (_event, data) => {
    if (events[_event]) {
        events[_event].forEach((listener) => {
            listener(data);
        });
    }
}

export function init() {
    return new Promise(resolve => {

        // agoraLibraryPath = 'https://raw.githubusercontent.com/linkroom/linkroomv3_assets/main/assets/libs/AgoraRTC_N-4.20.2.js';
        // agoraLibraryPath = "https://download.agora.io/sdk/release/AgoraRTC_N-4.22.2.js";
        // agoraLibraryPath = "https://ik.imagekit.io/b152q4ah5/AgoraRTC_N-4.22.2.js?updatedAt=1735922005912";
        // agoraLibraryPath = "https://firebasestorage.googleapis.com/v0/b/linkroom-v2-dev.appspot.com/o/linkroomv3_assets%2Flibs%2FAgoraRTC_N-4.20.2.js?alt=media&token=bf907276-327f-4b84-a7fb-6a949016ceac";
        agoraLibraryPath = "assets/libs/AgoraRTC_N-4.20.2.js";
        agoraExtensionVirtualBackgroundPath = "assets/libs/agora-extension-virtual-background.js";
        agoraExtesionVirtualBackground = "assets/libs/wasms"

        importingLibrary().then(async (AgoraRTC) => {
            AgoraRTC.setLogLevel(logLevel);
            getDevicesAvailable().then(() => {
                resolve();
            });
        }).catch((error) => {
            console.error("Failed to initialize AgoraRTC", error);
            reject(error);
        });


    });
}

export function createClient() {
    client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    shareClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    AgoraRTC.setParameter("AUDIO_VOLUME_INDICATION_INTERVAL", 100);
    client.enableAudioVolumeIndicator();

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("volume-indicator", handleUserVolumeIndicator);

    shareClient.on("user-published", handleUserSharePublished);
    shareClient.on("user-unpublished", handleUserShareUnpublished);

    client.on(EVENT.NETWORK_QUALITY, handleNetworkQuality);
}

function getDevicesAvailable() {

    return new Promise(async (resolve, reject) => {


        try {
            var results = await requestPermissions();
        } catch (error) {
            console.log(error);

        }

        AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
            var message = changedDevice.state == "ACTIVE" ? "Microphone found!" : "Microphone removed!";
            console.log(message);
            getMicrophonesAvailable();
        };

        AgoraRTC.onCameraChanged = async (changedDevice) => {
            var message = changedDevice.state == "ACTIVE" ? "Camera found!" : "Camera removed!";
            console.log(message);
            getCamerasAvailable();
        };

        Promise.all([getCamerasAvailable(), getMicrophonesAvailable()]).then((response) => {
            resolve();
        });
    });
}

function getCamerasAvailable() {
    return new Promise((resolve, reject) => {
        AgoraRTC.getCameras().then((_cams) => {
            cams = _cams
            camerasAvailable = cams.length > 0 ? true : false;
            emit("onCamerasAvailable", { available: camerasAvailable, cams });
            resolve();
        }, error => {
            console.log(error.name);
            console.log(error.code);
            if (error.name === "AgoraRTCException") {
                switch (error.code) {
                    case "PERMISSION_DENIED":
                        resolve();
                        break;
                    case "NOT_READABLE":
                        resolve();
                        break;
                    default:
                        requestPermissions();
                }
            } else if (error.name === "NotAllowedError") {
                requestPermissions();
            } else {
                console.error(`Error desconocido: ${error.message}`);
            }
            emit("onDevicePermissionDenied", {});
        }).catch(cat => {
            console.error(cat)
        })
    });

}

function getMicrophonesAvailable() {
    return new Promise((resolve, reject) => {
        AgoraRTC.getMicrophones().then((_mics) => {
            mics = _mics
            microphonesAvailable = mics.length > 0 ? true : false;
            emit("onMicrophonesAvailable", { available: microphonesAvailable, mics });
            resolve();
        }, error => {
            if (error.name === "AgoraRTCException") {
                switch (error.code) {
                    case "PERMISSION_DENIED":
                        resolve();
                        break;
                    default:
                        requestPermissions();
                }
            } else if (error.name === "NotAllowedError") {
                requestPermissions();
            } else {
                console.error(`Error desconocido: ${error.message}`);
            }
            emit("onDevicePermissionDenied", {});
        }
        ).catch(cat => {
            console.error(cat)
        })
    });

}

function handleUserPublished(user, mediaType) {

    return new Promise((resolve, reject) => {
        remoteUsers[user.uid] = user;
        client.subscribe(user, mediaType).then((remoteTrack) => {
            emit(EVENT.USER_PUBLISHED, { user, mediaType });
            console.log(remoteTrack);
            resolve(remoteTrack);
        }).catch((error) => {
            reject(error);
        });
    });

}

function handleUserUnpublished(user, mediaType) {
    try {
        return new Promise((resolve, reject) => {
            if (mediaType === 'video')
                delete remoteUsers[user.uid];

            emit(EVENT.USER_UNPUBLISHED, { user, mediaType });
            resolve();
        });
    } catch (error) {
        reject(error);
    }
}

function handleUserVolumeIndicator(volumes) {
    emit(EVENT.USER_VOLUMEINDICATOR, { volumes });
}

function handleUserSharePublished(user, mediaType) {
    return new Promise((resolve, reject) => {
        shareClient.subscribe(user, mediaType).then((remoteTrack) => {
            emit(EVENT.USER_SHARE_PUBLISHED, { user, mediaType });
            resolve(remoteTrack);
        }).catch((error) => {
            reject(error);
        });

    });
}

function handleUserShareUnpublished(user, mediaType) {
    emit(EVENT.USER_SHARE_UNPUBLISHED, { user, mediaType });
}

function handleNetworkQuality(stats) {
    const uplinkNetworkQuality = stats.uplinkNetworkQuality;
    const downlinkNetworkQuality = stats.downlinkNetworkQuality;
    const jitter = getJitterValue();
    const packetLoss = getPackageLossValue();
    const speakerDelay = getSpeakerDelayValue();
    emit(EVENT.NETWORK_QUALITY, { uplinkNetworkQuality, downlinkNetworkQuality, jitter, packetLoss, speakerDelay });
}

export function joinChannel(appId, channelName, token, id) {

    return new Promise((resolve, reject) => {

        client.join(appId, channelName, token || null, id || null).then((_uid) => {
            resolve({ agoraClientUID: _uid });
        }, error => {
            console.log(error);
            reject(error);
        }).catch(error => {
            reject(error);
        });

    });

};

export function joinShareScreenChanel(appId, channelName, token, id) {
    return new Promise((resolve, reject) => {
        shareClient.join(appId, channelName, token || null, id || null).then((_shareUid) => {
            resolve({ agoraShareUId: _shareUid });
        }, error => {
            console.log(error);
            reject(error);
        }).catch(error => {
            reject(error);
        });

    });
}

export function leaveChannel() {

    // if (localTracks.audioTrack) {
    //     localTracks.audioTrack.stop();
    //     localTracks.audioTrack.close();
    //     localTracks.audioTrack = null;
    // }

    // if (localTracks.videoTrack) {
    //     localTracks.videoTrack.stop();
    //     localTracks.videoTrack.close();
    //     localTracks.videoTrack = null;
    // }
    client.leave();
}

export function leaveShareScreenChannel() {
    shareClient.leave();
}


export function createAndPublishLocalTracks(selectedCamID, selectedMicID, videoEnabled, audioEnabled, blurEnabled) {

    return new Promise(async (resolve, reject) => {
        selectedMicIDLocal = selectedMicID;
        selectedCamIDLocal = selectedCamID;
        var [cam, mic] = await Promise.allSettled([createAndPublishVideoTrack(selectedCamID, videoEnabled, blurEnabled), createAndPublishAudioTrack(selectedMicID, audioEnabled)])
        resolve({ cam, mic });
    });
}

function createAndPublishAudioTrack(currentMicId, micEnabled) {
    return new Promise((resolve, reject) => {

        if (mics.length > 0) {

            var deviceFound = mics.find(cam => cam.deviceId == currentMicId);
            currentMicId = deviceFound ? deviceFound.deviceId : null;

            if (micEnabled) {
                createMicrophoneAudioTrack(currentMicId).then((audioTrack) => {
                    localTracks.audioTrack = audioTrack;
                    var v = experienceReady ? 100 : 0;
                    localTracks.audioTrack.setVolume(v);
                    if (isLocalTrackspublished) {
                        publishLocalTrack(localTracks.audioTrack).then(() => {
                            resolve(localTracks.audioTrack);
                        })
                    } else {
                        resolve(localTracks.audioTrack);
                    }
                }).catch((err) => {
                    reject(Constants.NO_MICROPHONE_AVAILABLE);
                });
            } else {
                resolve(null);
            }

        } else {
            reject(Constants.NO_MICROPHONE_AVAILABLE);
        }

    });
}

export function publishLocalTracks(_isLocalTrackspublished) {
    isLocalTrackspublished = _isLocalTrackspublished;
    if (localTracks.audioTrack) {
        publishLocalTrack(localTracks.audioTrack).then(() => {
            // resolve(localTracks.audioTrack);
        })
    }
    if (localTracks.videoTrack) {
        publishLocalTrack(localTracks.videoTrack).then(() => {
            // resolve(localTracks.videoTrack);
        })
    }
}


export function upVolume() {
    experienceReady = true;
    if (localTracks.audioTrack) {
        localTracks.audioTrack.setVolume(100);
    }
}

function createAndPublishVideoTrack(currentCamId, camEabled, blurEnabled) {
    return new Promise((resolve, reject) => {

        if (cams.length > 0) {

            var deviceFound = cams.find(cam => cam.deviceId == currentCamId);
            currentCamId = deviceFound ? deviceFound.deviceId : null;

            if (camEabled) {
                createCameraVideoTrack(currentCamId).then((videoTrack) => {
                    localTracks.videoTrack = videoTrack;
                    openVirtualBackground(processor).then((p) => {
                        console.log("Video track on Experience: ");

                        processor = p
                        pipeProcessor(localTracks.videoTrack, processor);

                        if (blurEnabled) {
                            enableBlurVideo(true);
                        }
                    });
                    if (isLocalTrackspublished) {
                        publishLocalTrack(localTracks.videoTrack).then(() => {
                            resolve(localTracks.videoTrack);
                        })
                    } else {
                        resolve(localTracks.videoTrack);
                    }
                }).catch((err) => {
                    reject(Constants.NO_CAMERA_AVAILABLE);
                });
            } else {
                resolve(null);
            }

        } else {
            reject(Constants.NO_CAMERA_AVAILABLE);
        }
    });
}


export async function enableAudio(enable) {
    return new Promise(async (resolve, reject) => {

        if (microphonesAvailable == true) {
            if (localTracks.audioTrack) {
                await localTracks.audioTrack.setEnabled(enable);
                resolve(localTracks.audioTrack.enabled);
            } else {
                createAndPublishAudioTrack(selectedMicIDLocal, enable).then(() => {
                    resolve(localTracks.audioTrack.enabled);
                })
            }
        } else {
            console.log("No microphones available");
            reject("No microphones available");
        }
    });
}

export async function enableVideo(enable) {
    return new Promise((resolve, reject) => {

        if (camerasAvailable == true) {
            if (localTracks.videoTrack) {
                try {
                    localTracks.videoTrack.setEnabled(enable).then(() => {
                        var _enabled = localTracks.videoTrack.enabled;
                        resolve({ success: true, enabled: _enabled });
                    }).catch((error) => {
                        reject({ success: false, enabled: false });
                    });
                } catch (error) {
                    reject({ success: false, enabled: false });
                }
            } else {
                console.log(Constants.VIDEO_TRACK_NOT_FOUND);
                // reject({ success: false, enabled: false });
                createAndPublishVideoTrack(selectedCamIDLocal, enable).then(() => {
                    resolve({ success: true, enabled: enable });
                });
            }
        } else {
            console.log("No cameras available");
            reject("No cameras available");
        }
    });
}

export function switchCamera(deviceId) {
    return new Promise((resolve, reject) => {
        if (localTracks.videoTrack) {
            currentCam = cams.find(cam => cam.deviceId === deviceId);
            localTracks.videoTrack.setDevice(currentCam.deviceId).then(() => {
                resolve();
            }).catch((err) => {
                reject();
            });
        } else
            reject(Constants.VIDEO_TRACK_NOT_FOUND);
    });
}

export function switchMicrophone(deviceId) {
    return new Promise(async (resolve, reject) => {
        if (localTracks.audioTrack) {
            currentMic = mics.find(mic => mic.deviceId === deviceId);
            await localTracks.audioTrack.setDevice(currentMic.deviceId).then(() => {
                resolve();
            });
        } else
            reject(Constants.AUDIO_TRACK_NOT_FOUND);
    });
}

export function playVideoCamera(videoElementID) {
    if (localTracks.videoTrack) localTracks.videoTrack.play(videoElementID, { mirror: true });
}


function publishLocalTrack(_localtrack) {
    return new Promise(resolve => {
        client.publish([_localtrack]).then(() => {
            resolve();
        });
    })
}


export function setVideoCameraEncoder(config = '120p_1') {
    if (localTracks.videoTrack)
        localTracks.videoTrack.setEncoderConfiguration(config);
}


// export function requestPermissions() {
//     return new Promise((resolve, reject) => {
//         navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(resolve).catch((e) => {
//             console.log(e);
//             Object.keys(e);
//             emit("onDeviceAlreadyUsed", {});
//             reject('COMMUNICATION: request permissions failed');
//         });
//     });
// }

export async function requestPermissions() {
    try {
        const cameraPermission = await navigator.permissions.query({ name: "camera" });
        const microphonePermission = await navigator.permissions.query({ name: "microphone" });

        if (cameraPermission.state === "granted" && microphonePermission.state === "granted") {
            console.log("Permisos ya otorgados");
            return Promise.resolve("Permisos ya otorgados");
        }

        return navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
            console.log("Permisos concedidos");
            return stream;
        }).catch((e) => {
            console.log("Error al solicitar permisos:", e);
            emit("onDeviceAlreadyUsed", {});
            return Promise.reject("COMMUNICATION: request permissions failed");
        });
    } catch (error) {
        console.error("Error al verificar los permisos:", error);
        return Promise.reject("Error al verificar los permisos");
    }
}

export function createAndPublishShareTrack() {
    return new Promise(resolve => {
        createShareTrack({ encoderConfig: "1080p_2" }).then((track) => {
            localSCreenTrack = track;
            console.log('publish share Track');
            localSCreenTrack.on("track-ended", () => {
                emit(EVENT.STOP_SCREEN_SHARE, {});
            });

            shareClient.publish(localSCreenTrack).then(() => {
                resolve();
            })
        });

    })
}

export function playScreenShare(videoScreenElementID) {
    if (localSCreenTrack) localSCreenTrack.play(videoScreenElementID, { mirror: false });
}



export function unpublishShareTrack() {
    return new Promise(resolve => {
        console.log('unpublish share Track');
        shareClient.unpublish(localSCreenTrack).then(() => {
            if (localSCreenTrack) {
                localSCreenTrack.stop();
                localSCreenTrack.close();
                localSCreenTrack = null;
            }
            resolve();
        })
    })
}


export function getCurrentFrameData() {
    return new Promise((resolve, reject) => {
        var data = null;
        if (localTracks.videoTrack != null) {
            const imageData = localTracks.videoTrack.getCurrentFrameData();
            data = {
                imageData: imageData.data.buffer,
                width: imageData.width,
                height: imageData.height,
                format: 'Uint8ClampedArray'
            };
        }

        resolve(data);
    });
}

export function getCurrentFrameDataSettings() {
    return new Promise((resolve, reject) => {
        var data = null;
        if (localTracksSettings.videoTrack != null) {
            const imageData = localTracksSettings.videoTrack.getCurrentFrameData();
            data = {
                imageData: imageData.data.buffer,
                width: imageData.width,
                height: imageData.height,
                format: 'Uint8ClampedArray'
            };
        }

        resolve(data);
    });
}

function getJitterValue() {
    if (!client) return 0;
    const videoStats = client.getLocalVideoStats();
    const jitter = videoStats.sendJitterMs || 0;
    return parseFloat(jitter.toFixed(2)); // Return jitter in ms
}

function getPackageLossValue() {
    if (!client) return 0;
    const localAudioStats = client.getLocalAudioStats();
    const remoteAudioStats = client.getRemoteAudioStats();

    let maxRemotePacketLoss = 0;

    for (const uid in remoteAudioStats) {
        const remoteStat = remoteAudioStats[uid];
        if (remoteStat.packetLossRate !== undefined) {
            maxRemotePacketLoss = Math.max(maxRemotePacketLoss, remoteStat.packetLossRate);
        }
    }

    const localPacketLoss = localAudioStats.currentPacketLossRate || 0; // Local packet loss rate
    //   const remotePacketLoss = remoteAudioStats.packetLossRate || 0; // Remote packet loss rate

    const packetLoss = Math.max(localPacketLoss, maxRemotePacketLoss); // Use the higher value
    return packetLoss;
}

function getSpeakerDelayValue() {
    if (!client) return 0;
    const remoteAudioStats = client.getRemoteAudioStats();

    let maxSpeakerDelay = 0;
    for (const uid in remoteAudioStats) {
        const remoteStat = remoteAudioStats[uid];
        if (remoteStat.end2EndDelay !== undefined) {
            maxSpeakerDelay = Math.max(maxSpeakerDelay, remoteStat.end2EndDelay);
        }
    }

    // const decodeDelay = remoteAudioStats.decodeDelay || 0; // Decode delay in milliseconds
    return maxSpeakerDelay || 0;
}

//SETTINGS
function createVideoTrackSettings(currentCamId, camEabled, blurEnabled) {
    return new Promise((resolve, reject) => {

        if (cams.length > 0) {

            var deviceFound = cams.find(cam => cam.deviceId == currentCamId);
            currentCamId = deviceFound ? deviceFound.deviceId : null;

            if (localTracksSettings.videoTrack == null) {
                createCameraVideoTrack(currentCamId).then((videoTrack) => {

                    localTracksSettings.videoTrack = videoTrack;
                    openVirtualBackground(processorSettings).then((p) => {
                        processorSettings = p
                        pipeProcessor(localTracksSettings.videoTrack, processorSettings);
                        if (blurEnabled) {
                            enableBlurVideoSettings(true);
                        }
                    });
                    if (camEabled == false) {
                        enableVideoSettings(false);
                    }
                    resolve(cams);
                }).catch((err) => {
                    reject(Constants.NO_CAMERA_AVAILABLE);
                });
            } else {
                if (blurEnabled) {
                    enableBlurVideoSettings(true);
                } else {
                    enableBlurVideoSettings(false);
                }

                if (camEabled == false) {
                    enableVideoSettings(false);
                } else {
                    enableVideoSettings(true);
                }
                resolve(cams);
            }

        } else {
            reject(Constants.NO_CAMERA_AVAILABLE);
        }
    });
}

function getPlaybackDevicesSettings() {
    return new Promise((resolve, reject) => {
        AgoraRTC.getPlaybackDevices().then((_playbackDevices) => {
            playbacks = _playbackDevices;
            resolve(playbacks);
        });
    });
}


function createAudioTrackSettings(currentMicId, micEnabled) {
    return new Promise((resolve, reject) => {

        if (mics.length > 0) {

            var deviceFound = mics.find(mic => mic.deviceId == currentMicId);
            currentMicId = deviceFound ? deviceFound.deviceId : null;

            if (localTracksSettings.audioTrack == null) {
                createMicrophoneAudioTrack(currentMicId).then((audioTrack) => {
                    localTracksSettings.audioTrack = audioTrack;
                    if (micEnabled) {
                        enableAudioSettings(false);
                    }
                    resolve(mics);
                }).catch((err) => {
                    reject(Constants.NO_MICROPHONE_AVAILABLE);
                });
            } else {
                resolve(mics);
            }
        } else {
            reject(Constants.NO_MICROPHONE_AVAILABLE);
        }
    });
}

export function switchCameraSettings(deviceId) {
    return new Promise((resolve, reject) => {
        if (localTracksSettings.videoTrack) {
            currentCamSettings = cams.find(cam => cam.deviceId === deviceId);
            localTracksSettings.videoTrack.setDevice(currentCamSettings.deviceId).then(() => {
                resolve();
            }).catch((err) => {
                reject();
            });
        } else
            reject(Constants.VIDEO_TRACK_NOT_FOUND);
    });
}

export function switchMicrophoneSettings(deviceId) {
    return new Promise(async (resolve, reject) => {
        if (localTracksSettings.audioTrack) {
            currentMicSettings = mics.find(mic => mic.deviceId === deviceId);
            await localTracksSettings.audioTrack.setDevice(currentMicSettings.deviceId).then(() => {
                resolve();
            });
        } else
            reject(Constants.AUDIO_TRACK_NOT_FOUND);
    });
}

export async function enableAudioSettings(enable) {
    return new Promise(async (resolve, reject) => {
        if (localTracksSettings.audioTrack) {
            await localTracksSettings.audioTrack.setEnabled(enable);
            resolve(localTracksSettings.audioTrack.enabled);
        } else
            reject(Constants.AUDIO_TRACK_NOT_FOUND);
    });
}


export async function enableVideoSettings(enable) {
    return new Promise((resolve, reject) => {
        if (localTracksSettings.videoTrack) {
            try {
                localTracksSettings.videoTrack.setEnabled(enable).then(() => {
                    var _enabled = localTracksSettings.videoTrack.enabled;
                    resolve({ success: true, enabled: _enabled });
                }).catch((error) => {
                    reject({ success: false, enabled: false });
                });
            } catch (error) {
                reject({ success: false, enabled: false });
            }
        } else {
            console.log(Constants.VIDEO_TRACK_NOT_FOUND);
            reject({ success: false, enabled: false });
        }
    });
}



export function playVideoTrackSettings(videoElement) {
    if (videoElement != null)
        localTracksSettings.videoTrack.play(videoElement, { mirror: true });
}

export function closeLocalTracksSettings() {

    if (localTracksSettings.audioTrack) {
        localTracksSettings.audioTrack.stop();
        localTracksSettings.audioTrack.close();
        localTracksSettings.audioTrack = null;
    }

    if (localTracksSettings.videoTrack) {
        localTracksSettings.videoTrack.stop();
        localTracksSettings.videoTrack.close();
        localTracksSettings.videoTrack = null;
    }
}

export function setVolumeWaveSettings(enable) {
    if (enable)
        volumeAnimation = requestAnimationFrame(volumeWaveSettings);
    else
        cancelAnimationFrame(volumeAnimation);
}

export function enableBlurVideoSettings(enabled) {
    return new Promise(async (resolve, reject) => {
        if (localTracksSettings.videoTrack != null) {
            processorSettingsEnabled = enabled;
            enableVirtualBackground(processorSettings, processorSettingsEnabled);
            resolve();
        } else {
            resolve();
        }
    });
}

export function enableBlurVideo(enabled) {
    return new Promise(async (resolve, reject) => {
        if (localTracks.videoTrack != null) {
            processorEnabled = enabled;
            await enableVirtualBackground(processor, processorEnabled);
            resolve();
        } else {
            resolve();
        }

    });

}

async function openVirtualBackground(_processor) {
    return new Promise(async (resolve, reject) => {
        denoiser = denoiser || (() => {
            let denoiser = new VirtualBackgroundExtension();
            AgoraRTC.registerExtensions([denoiser]);
            return denoiser;
        })();

        _processor = await (async () => {
            let processor = denoiser.createProcessor();
            processor.eventBus.on("PERFORMANCE_WARNING", () => {
                console.warn("Performance warning!!!!!!!!!!!!!!!!!");
            });
            try {
                await processor.init(agoraExtesionVirtualBackground);
            } catch (error) {
                console.error(error);
                processor = null;
            }
            return processor;
        })();
        resolve(_processor);
    });

}

export async function enableVirtualBackground(_processor, enabled) {
    if (enabled) {
        try {
            await _processor.enable();
        } catch (e) {
            console.error("enable VirtualBackground failure", e);
        } finally {
        }
    } else {
        try {
            await _processor.disable();
        } catch (e) {
            console.error("disable VirtualBackground failure", e);
        } finally {
        }
    }
}

const pipeProcessor = (track, _processor) => {
    try {
        track.pipe(_processor).pipe(track.processorDestination);
    } catch (error) {
        console.error("pipe processor failure", error);
    }

};

function volumeWaveSettings() {
    volumeAnimation = requestAnimationFrame(volumeWaveSettings);
    if (localTracksSettings.audioTrack != null) {
        percentage = localTracksSettings.audioTrack.getVolumeLevel() * 100;
    }
    emit(EVENT.VOLUME_WAVE_SETTINGS, percentage);
}

export function settings(currentMicId, currentCamId, micEnabled, camEnabled, blurEnabled) {
    return new Promise(async (resolve, reject) => {
        var [cams, mics, playbacks] = await Promise.allSettled([createVideoTrackSettings(currentCamId, camEnabled, blurEnabled), createAudioTrackSettings(currentMicId, micEnabled), getPlaybackDevicesSettings()]);
        resolve({ cams, mics, playbacks });
    });
}



function importingLibrary() {
    return new Promise((resolve, reject) => {
        fetch(agoraLibraryPath).then(response => {

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.text();
        }).then(data => {


            let agoraLibraryFound = document.getElementById("agora-library");
            if (agoraLibraryFound == null) {
                const script = document.createElement('script');
                script.id = "agora-library"
                script.textContent = data;
                document.head.appendChild(script);
            } else {
            }
            importExtensionVirtualBackground();
            resolve(AgoraRTC);

        }).catch(error => {
            console.error('Error al cargar el script:', error);
        });
    });
}

function importExtensionVirtualBackground() {
    return new Promise((resolve, reject) => {

        fetch(agoraExtensionVirtualBackgroundPath).then(response => {

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.text();
        }).then(data => {

            let agoraExtensionBackgroundFound = document.getElementById("agora-extension-background");
            if (agoraExtensionBackgroundFound == null) {
                const script = document.createElement('script');
                script.id = "agora-extension-background"
                script.textContent = data;
                document.head.appendChild(script);
            } else {
            }

            resolve();
        }).catch(error => {
            console.error('Error al cargar el script:', error);
        });
    });
}