import { Component, inject } from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'app-attendee-kicked',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './attendee-kicked.component.html',
  styleUrl: './attendee-kicked.component.css'
})
export class AttendeeKickedComponent {
  authService = inject(AuthService);
  translateService = inject(TranslateService);

  title: string = '';
  message: string = '';

  async ngOnInit() {
    let messageTitleKey: string | null = localStorage.getItem('message-title');
    let messageContentKey: string | null = localStorage.getItem('message-key');

    this.title = await firstValueFrom(
      this.translateService.get(messageTitleKey || 'kicked-user.title')
    );

    this.message = await firstValueFrom(
      this.translateService.get(messageContentKey || 'kicked-user.message')
    );

    localStorage.removeItem('message-title');
    localStorage.removeItem('message-key');

    this.authService.user$.subscribe(async user => {
      if (user && this.authService.firebaseUser?.isAnonymous) {
        await this.authService.signOut();
      }
    });
  }

  redirectToHome() {
    location.assign('/');
  }
}
