import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';

import CryptoJS from 'crypto-js';

import { OrganizationService } from '@features/services/organization.service';
import { ProfileService } from '@features/services/profile.service';

import { CRYPTO_SEED } from './constants';

export const getInitials = (name: string): string => {
  return (name.length > 1 ? name.substring(0, 2) : name).toUpperCase();
};

export const generateRoomUUID = (): string => {
  const segmentsLength = [3, 4, 3];
  let idSegments = [];

  for (let j = 0; j < segmentsLength.length; j++) {
    let segment = '';
    for (let k = 0; k < segmentsLength[j]; k++) {
      const charCode = Math.floor(Math.random() * (122 - 97 + 1)) + 97; // Random ASCII code for 'a' (97) to 'z' (122)
      segment += String.fromCharCode(charCode);
    }
    idSegments.push(segment);
  }

  return idSegments.join('-');
};

export const sortListObject = (
  dataList: any[],
  field: string,
  direction: 'ascending' | 'descending'
) => {
  dataList.sort((a, b) => {
    let fieldType = typeof a[field];
    let x, y;

    switch (fieldType) {
      case 'string':
        x = a[field].toLowerCase();
        y = b[field].toLowerCase();
        break;
      case 'number':
        x = a[field];
        y = b[field];
        break;
    }

    if (x < y) return direction == 'ascending' ? -1 : 1;
    if (x > y) return direction == 'ascending' ? 1 : -1;

    return 0;
  });
};

export const encryptData = (value: string): string => {
  const key = CRYPTO_SEED;
  const keyutf = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Base64.parse(key);
  const enc = CryptoJS.AES.encrypt(value, keyutf, { iv: iv });

  return enc.toString();
};

export const decryptData = (data: string): any => {
  try {
    const key = CRYPTO_SEED;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    const bytes = CryptoJS.AES.decrypt(data, keyutf, { iv: iv });
    if (bytes.toString()) {
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const resolveResourcePath = (resourcePath: string): URL => {
  let xx = new URL(resourcePath, import.meta.url);
  return new URL(resourcePath, import.meta.url);
};

export const getRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export const generateGUID = (): string => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const getProperCaseText = (text: string) => {
  try {
    let nameWords: string[] = text.split(' ');
    let finalName: string = '';

    nameWords.forEach(w => {
      finalName += `${w[0].toUpperCase()}${w.substring(1, w.length).toLowerCase()} `;
    });

    return finalName.trim();
  } catch (error: any) {
    return text;
  }
};

export const readFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      resolve(e.target.result);
    };

    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
  });
};

export const getParsedMessage = (message: string): string => {
  let parsedMessage: string = '';
  let pattern: RegExp | string = '^([a-zA-Z0-9]+(.[a-zA-Z0-9]+)+.*)$';
  let patternTest = /^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/;

  if (patternTest.test(message) && message.substring(0, 3) != 'www') {
    parsedMessage = `<a href="${message}" target="_blank">${message}</a>`;
  } else {
    let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    let text1 = message.replace(exp, `<a href='$1' target="_blank">$1</a>`);
    pattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

    parsedMessage = text1.replace(pattern, `$1<a target="_blank" href="http://$2">$2</a>`);
  }

  return parsedMessage;
};

export const getCurrentHourMinute = (): string =>
  new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

export const base64ToBlob = (base64String: string, contentType: string) => {
  let byteCharacters = atob(base64String.split(',')[1]);

  let byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  let byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type: contentType });
};

export const loadProfileService = async (injector: Injector): Promise<ProfileService> => {
  const { ProfileService } = await import('@features/services/profile.service');
  const httpClient = injector.get(HttpClient);
  return new ProfileService(httpClient);
};

export const formatDigits = (value: number) => {
  return value.toString().padStart(2, '0');
};
