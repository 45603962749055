import { KeyValue } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Subscription } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { I18nService } from '@core/services/i18n.service';
import { ModalService } from '@core/services/modal.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from '@environment';
import { SelectOrganizationComponent } from '@features/components/select-organization/select-organization.component';
import { UserSettingsComponent } from '@features/components/user-settings/user-settings.component';
import { OrganizationViewModel } from '@features/models/organization.model';
import { PresentationViewModel } from '@features/models/presentation.model';
import { ProfileNameEmail, ProfileViewModel } from '@features/models/profile.model';
import { AccessRequestViewModel } from '@features/models/support-access.model';
import { AccessRequestService } from '@features/services/access-request.service';
import { OrganizationService } from '@features/services/organization.service';
import { PresentationService } from '@features/services/presentation.service';
import { ProfileService } from '@features/services/profile.service';
import { APP_LANGUAGES, APP_NAME, LOCAL_STORAGE_KEYS } from '@shared/utils/constants';
import { getInitials } from '@shared/utils/functions';

import { SelectOptionComponent } from '../select-option/select-option.component';
import { CustomDatePipe } from '@shared/pipes/custom-date.pipe';

declare let window: any;

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [RouterModule, TranslateModule, ReactiveFormsModule, UserSettingsComponent, CustomDatePipe],
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.css',
  encapsulation: ViewEncapsulation.None
})
export class NavBarComponent implements OnInit, OnDestroy {
  hasLicense = false;
  isSaUser = false;
  permissions: string[] = [];
  organizations: OrganizationViewModel[] = [];
  public accessRequests: AccessRequestViewModel[] = [];
  showSupportRequests = false;
  showAccountOptions = false;
  showSelfSettings = false;
  showPermissions = false;

  public presentations: PresentationViewModel[] = [];
  public sharedPresentationsWithMe: PresentationViewModel[] = [];
  public profile: ProfileViewModel | null = null;
  public selectedPresentation: PresentationViewModel | undefined;

  private organizationSub: Subscription | null = null;
  private userSub: Subscription | null = null;

  constructor(
    public authService: AuthService,
    public organizationService: OrganizationService,
    private profileService: ProfileService,
    private i18nService: I18nService,
    private modalService: ModalService,
    private presentationService: PresentationService,
    private notificationService: NotificationService,
    private accessRequestService: AccessRequestService,
    private translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.organizationSub?.unsubscribe();
    this.userSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.userSub = this.authService.user$.subscribe(user => {
      this.profile = user;
    });

    this.organizationSub = this.organizationService.organizationList$.subscribe(
      async organizations => {
        if (organizations.length > 0) {
          if (!this.organizationService.currentOrganization) {
            let organizationId = localStorage.getItem(
              LOCAL_STORAGE_KEYS.PROFILE_CURRENT_ORGANIZATION_ID
            );

            if (organizationId) {
              await this.organizationService.selectOrganization(organizationId);
            } else {
              if (organizations.length > 1) {
                this.modalService.open(SelectOrganizationComponent);
              } else if (organizations.length === 1) {
                await this.organizationService.selectOrganization(organizations[0]._id);
              }
            }
          }
        }
      }
    );

    this.organizationService.currentOrganization$.subscribe(currentOrganization => {
      if (currentOrganization) {
        this.loadPermissions();
      }
    });

    document.addEventListener('click', e => {
      let element: any = e.target;
      let className: string | undefined = element.className;
      let validClasses = ['toggleUserOptions', 'username'];
      if (!className || !validClasses.includes(className)) {
        if (this.showAccountOptions) {
          this.toggleAccountOptions();
        }
      }
    });
  }

  async galleryRedirect() {
    try {
      const user = this.authService.firebaseUser;
      if (!user) return;
      const token = await user.getIdToken();
      const redirectUrl = window.location.origin;

      let httpParams = new HttpParams()
        .set('token', token)
        .set('redirectUrl', redirectUrl)
        .set('organization', this.organizationService.currentOrganization!._id);

      let url = `${environment.external.gallery}/auth?${httpParams.toString()}`;
      window.open(url, '_blank');
    } catch (error) {
      console.error(error);
    }
  }

  async loadPermissions() {
    this.hasLicense = false;

    const permissionsResponse = await firstValueFrom(
      this.profileService.getPermissions(this.organizationService.currentOrganization!._id)
    );

    if (permissionsResponse.data) {
      this.hasLicense = true;
      this.permissions = Object.values(permissionsResponse.data);
      // localStorage.setItem(
      //   LOCAL_STORAGE_KEYS.PERMISSIONS_LIST,
      //   btoa(JSON.stringify(permissionsResponse.data))
      // );

      // const permissionsToSet: any = {};
      // const localPermissions = Object.keys(Constants.EditPermissions);

      // const permissionData = localStorage.getItem(LOCAL_STORAGE_KEYS.PERMISSIONS_LIST)!;
      // const profilePermissions: any = JSON.parse(atob(permissionData).toUpperCase());
      // const profilePermissionKeys = Object.keys(profilePermissions);

      // for (const permission of localPermissions) {
      //   permissionsToSet[permission] = profilePermissionKeys.includes(permission);
      // }

      // Permissions.getInstance().setEditPermissions(permissionsToSet);
      // this.canCreatePixelStreaming = this.permissionsService.evalPermission('CREATE_PIXEL_ROOM'); //this.permissionsService.evalPermission('CAN_USE_PIXEL')
    } else {
      // localStorage.setItem(LOCAL_STORAGE_KEYS.PERMISSIONS_LIST, '[]');
    }
  }

  showProfilePermissions(show: boolean) {
    this.showPermissions = show;
    this.hideMainScrollBar(show);
  }

  promptOrganizationSelect() {
    let currentOrganizationId: string = this.organizationService.currentOrganization!._id;
    this.modalService.open(SelectOrganizationComponent, { currentOrganizationId });
  }

  goToAdmin() {
    window.open(environment.external.admin, '_blank');
  }

  async logout() {
    await this.authService.signOut();
    location.assign('/sign-in');
  }

  toggleAccountOptions() {
    this.showAccountOptions = !this.showAccountOptions;
  }

  //#region  Support Requests
  async toggleSupportRequests() {
    this.showSupportRequests = !this.showSupportRequests;
    this.showSupportRequests ? await this.listAccessRequests() : (this.accessRequests = []);
    this.hideMainScrollBar(this.showSupportRequests);
  }

  private async listAccessRequests() {
    const user = await this.authService.getCurrentUserAsync();
    if (!user) return;

    const requests = await firstValueFrom(this.accessRequestService.list(user.uid));
    this.accessRequests = requests.data || [];
  }

  formatDuration(seconds: number) {
    if (seconds % (60 * 60 * 1000) === 0) {
      const hours = seconds / (60 * 60 * 1000);
      return `${hours}h`;
    } else if (seconds % (60 * 1000) === 0) {
      const minutes = seconds / (60 * 1000);
      return `${minutes}m`;
    } else {
      return `${seconds / 1000}s`;
    }
  }

  async updateAccessRequest(id: string, status: 'approved' | 'denied') {
    await firstValueFrom(this.accessRequestService.update(id, this.profile?._id!, status));
    const title = this.translateService.instant('nav-bar.support.title');
    const stat = this.translateService.instant(`nav-bar.support.${status}`);

    const message = this.translateService.instant('nav-bar.support.message', {
      status: stat
    });

    this.modalService.alert({
      message: message,
      title,
      icon: status === 'approved' ? 'success' : 'error',
      duration: 4000
    });

    this.toggleSupportRequests();
  }

  //#endregion

  async showPresentations(show: boolean) {
    document.getElementById('presentations')!.style.display = show ? '' : 'none';
    show ? await this.listPresentations() : (this.presentations = []);
    this.hideMainScrollBar(show);
  }

  async sharePresentations(presentationId: string) {
    this.showPresentations(false);
  }

  displayRecipients(show: boolean, presentation: PresentationViewModel | undefined = undefined) {
    document.getElementById('presentation-recipients')!.style.display = show ? '' : 'none';
    document.getElementById('presentations')!.style.display = show ? 'none' : '';
    this.selectedPresentation = presentation;
  }

  removeSharedPresentationRecipient(id: string) {
    window['roomListComponentReference'].zone.run(() =>
      window['roomListComponentReference'].openConfirmDialog(
        'Remove Recipient',
        'Are you sure you want to remove the recipient?',
        async () => {
          this.notificationService.notifyUser(APP_NAME, 'Removing...', 'info');
          await firstValueFrom(this.presentationService.deleteSharedPresentation(id));
          this.notificationService.notifyUser(APP_NAME, 'Removed', 'success');
          let index = this.selectedPresentation?.recipients.findIndex(
            r => r.sharedPresentationId == id
          );
          this.selectedPresentation?.recipients.splice(index!, 1);
        }
      )
    );
  }

  removeSharedPresentation(sharedPresentation: PresentationViewModel, index: number) {
    window['roomListComponentReference'].zone.run(() =>
      window['roomListComponentReference'].openConfirmDialog(
        'Shared Presentation',
        'Are you sure you want to remove the shared presentation?',
        async () => {
          this.notificationService.notifyUser(APP_NAME, 'Removing...', 'info');
          const currentOrganizationId: string = window['roomListComponentReference'].zone.run(() =>
            window['roomListComponentReference'].getCurrentOrganizationId()
          );

          const existingResp = await firstValueFrom(
            this.presentationService.getSharedPresentation(
              currentOrganizationId,
              sharedPresentation._id,
              this.authService.user?._id ? this.authService.user?._id : ''
            )
          );

          await firstValueFrom(
            this.presentationService.deleteSharedPresentation(existingResp.data._id)
          );

          this.notificationService.notifyUser(APP_NAME, 'Removed', 'success');
          this.sharedPresentationsWithMe.splice(index, 1);
        }
      )
    );
  }

  showSelectLanguage() {
    let languages: KeyValue<string, string>[] = APP_LANGUAGES;

    this.modalService
      .open(SelectOptionComponent, { title: 'Language', options: languages, shouldTranslate: true })
      ?.subscribe(language => {
        if (language) {
          localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE, language);
          this.i18nService.setLocale(language);
        }
      });
  }

  hideMainScrollBar(hide: boolean) {
    document.body.style.overflow = hide ? 'hidden' : '';
  }

  getUserInitials() {
    if (this.authService.user) {
      let fullName = `${this.authService.user?.firstName} ${this.authService.user?.lastName}`;
      return getInitials(fullName);
    } else {
      return '...';
    }
  }

  getUserName() {
    return `${this.authService.user?.firstName ?? ''} ${this.authService.user?.lastName ?? ''}`;
  }

  private async listPresentations() {
    let organizationProfiles: ProfileNameEmail[] = [];

    const organization = localStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE_CURRENT_ORGANIZATION_ID);
    if (!organization) return;

    const [presentations, sharedPresentationsWithMe, sharedPresentationsByOwner] =
      await Promise.all([
        firstValueFrom(this.presentationService.list(organization)),
        firstValueFrom(this.presentationService.listSharedPresentations(organization)),
        firstValueFrom(
          this.presentationService.listSharedPresentationsByOwner(
            organization,
            this.authService.user?._id ? this.authService.user?._id : ''
          )
        )
      ]);

    this.sharedPresentationsWithMe = sharedPresentationsWithMe.data || [];
    this.presentations = presentations.data.map(p => ({ ...p, recipients: [] }));

    for (const presentation of this.presentations) {
      const sharedPresentations = sharedPresentationsByOwner.data.filter(
        p => p.presentation == presentation._id
      );

      if (sharedPresentations.length > 0) {
        const recipientsData = sharedPresentations.map(p => {
          const foundProfile = organizationProfiles.find(op => op._id == p.recipient);
          if (foundProfile) {
            return {
              sharedPresentationId: p._id,
              name: `${foundProfile.firstName || '-'} ${foundProfile.lastName || '-'}`,
              email: foundProfile.email || '-'
            };
          } else {
            return { sharedPresentationId: '', name: 'not found', email: 'not found' };
          }
        });

        presentation.recipients = recipientsData;
      }
    }
  }
}
