import { MSG_CODE } from "./constants";
import {
    createOffscreen,
    viewerManageWorkerMessages,
    loadScene,
    manageRendererMessages,
    viewerWorkerPromises,
    performUIAction,
    setLightsEnabled,
    setHelpersEnabled,
    setFeaturesEnabled,
    setViewerModules,
    remoteUserJoinned,
    updateRemoteLinkpads,
    removeLinkpad,
    spawnUser,
    setLinkpadVideo,
    deleteSceneObject,
    viewerResolve,
    viewerReject,
    getCameraPositionAndRotation,
    setVideoEnabledOnLinkpad,
    setAudioEnabledOnLinkpad,
    loadGalleryLODObject,
    createInstancedMeshLODSceneObject,
    createInstancedMeshLODSceneObjectFromDB,
    placeLoadingObject,
    placeSceneObject,
    createSceneObjectByBuffers,
    createSceneObjectDisplay,
    placeSceneObjectDisplay,
    loadTextureForImageSceneObject,
    placeImageSceneObject,
    remoteUpdateSceneObject,
    remoteDeleteSceneObject,
    setRemoteUserSpawnSetup,
    setVideoTextureToprojector,
    resetProjector,
    setRoomElementsForOrbit,
    getLocalActor,
    setFollow,
    getSetRemoteActor,
    createNotFoundObject,
    startFitToObject,
    stopFitToObject,
    updateActionPointerData,
    setUnFollow,
    setVolumeIndicator,
    setPointerVisibility,
    updateLinkpad,
    getRemoteLinkpadPosition,
    setSceneObjectLock,
    navigationFocusLoss,
    remoteAllLinkpads,
    forceDisposePreview
    //updateLinkpadNameResolve
} from "./controllers/viewerModuleController";
import {
    setNavigationModule,
    initNavigationModule,
    setTransformMode,
    getNavigationRaycast,
    getPlayerPosition,
    moveToTarget,
    lookAtTarget,
    setupDOMNavigationModuleController,
    getSelectedSceneObject,
    navigationResolve,
    navigationReject,
    getPlayerHeight,
    movePlayerInmediatlyTo,
    setEnableTransformControls,
    lookAtTargetInmediatly,
    enablePlayerMovementAndRotation,
    forceUnselectSceneObject,
    actionOrbitToSceneObject,
    actionExitOrbit,
    actionOrbitToPosition,
    orbitToSceneObject,
    exitOrbitMode,
    start360CameraRotation,
    setCurrentAction,
    setSelectedUsers,
    changeState,
    navigateToUser,
    goToSceneObject,
    inputKeyNavigation
} from "./controllers/navigationModuleController";
import {
    initNetWorkingModule,
    connectToRoom,
    sendLinkpadsTransformMessages,
    sendOnSceneObjectCreateOrUpdate,
    networkingSubscribe,
    experienceReadyOnNetworking,
    initNetworkingWithoutModule,
    disconnect,
    startMeeting,
    sendMessageViaChat,
    sendRoomEvent,
    updateMyActorData,
    sendPaymentStatusMessage,
    myActorConnected,
    getScreenShareInfoFromRoomProperty,
    sendNetworkingUpdateLinkpad,
    sendDeleteSceneObjectFromNetwork,
    persistPositionAndRotationOnFocusLoss,
    FocusFound,
    networkingReconnection
} from "./controllers/networkingModuleController";
import {
    initCommunicationModule,
    createLocalTracks,
    JoinChannelAndPublishTracks,
    enableAudio,
    enableVideo,
    enableBlurVideo,
    enableBlurVideoSettings,
    communicationSubscribe,
    setDeviceStateOnNewRemoteLinkpadConnected,
    experienceReadyOnCommunication,
    initCommunicationWithoutModule,
    leaveChannel,
    switchVideoElementId,
    switchCamera,
    switchMicrophone,
    getCurrentFrameData,
    communicationConnected
} from "./controllers/communicationModuleController";
import {
    initObjectsModule,
    initObjectsOffscreen,
    getGalleryObjects,
    loadSceneObject,
    sceneObjectAction,
    objectsWorkerPromises,
    registerSceneObject,
    getSceneObject,
    loadSceneObjectsFromDB,
    deleteSceneObjectById,
    setDisplayToSelectedProjector,
    getAssetById,
    sendVideoTextureToProjector,
    navigateToObject,
    screenShareAction,
    sendCallbacks
} from "./controllers/objectsModuleController";
import {
    initActionModule,
    loadActions,
    hostActionReciever,
    pointerReceiver,
    setHost,
    changeStateActionCancel,
    getIsFreeze,
    sendMessageToPlayer,
    getIsMuteHost,
    sendSimpleAction
} from "./controllers/actionModuleController";
import { initUtils, getSpecs } from "./utils";
import { v4 as uuidv4 } from "uuid";

let defaultResponse;
let viewerModule;
let objectsModule;
let communicationModule;
let networkingModule;
let navigationModule;
let actionModule;

export let offscreenPromises = {};

let singleExperience = false;
let organizationData;
let screenShareElementID = null;

var graphicsConnected = false;

export function init(options) {
    // console.log("Init Interface", options);
    
    initUtils(options);

    defaultResponse = options.callbacks.defaultResponse;
    objectsModule = options.modules.objectsModule;
    communicationModule = options.modules.communicationModule;
    networkingModule = options.modules.networkingModule;
    singleExperience = options.roomOptions.singleExperience;
    organizationData = options.roomOptions.organizationData;
    screenShareElementID = options.roomOptions.screenShareElementID;

    if (options.rendererOptions.useOffscreen) {
        options.workers.offscreenCanvasWorker.onmessage = ({ data }) => {
            manageDOMWorkerResponses(data);
        };
        initOffScreenModules(options);
    } else {
        viewerModule = options.modules.viewerModule;
        navigationModule = options.modules.navigationModule;
        setViewerModules(viewerModule, navigationModule);
        setNavigationModule(navigationModule);
    }

    networkingSubscribe("myActorConnected", () => {
        validateCommunicationAndNetworkingAndGraphicsConnected();
    });

    networkingSubscribe("newRemoteActorConnected", (photonId) => {
        setDeviceStateOnNewRemoteLinkpadConnected(photonId);
    });

    communicationSubscribe("communicationConnected", () => {
        validateCommunicationAndNetworkingAndGraphicsConnected();

    });

    setupRenderer(options);

    initDOMModules(options);


    //testing to send data to Offscreen
}

function validateCommunicationAndNetworkingAndGraphicsConnected() {
    if (communicationConnected && myActorConnected && graphicsConnected) {
        // console.log("COMMUNICATION AND NETWORKING AND GRAPHICS CONNECTED");
        // setDeviceStateOnLinkpads();
        experienceReadyOnNetworking();
        experienceReadyOnCommunication();

        var screenShareInfo = getScreenShareInfoFromRoomProperty();

        if (screenShareInfo) {
            if (screenShareInfo.active) {
                var videoElement = document.getElementById(screenShareElementID);
                sendVideoTextureToProjector(videoElement, screenShareInfo.displayMaterialName, screenShareInfo.uuid, screenShareInfo.assetId);
            }
        }
    }
}


function initDOMModules(options) {

    if (objectsModule)
        initObjectsModule(options);

    if (networkingModule) {
        initNetWorkingModule(options).then(() => {
            connectToRoom();
        });
    } else
        initNetworkingWithoutModule(options).then(() => { });

    if (communicationModule) {
        initCommunicationModule(options).then(() => {
            createLocalTracks().then(() => {
                if (options.roomOptions.singleExperience == false) {
                    JoinChannelAndPublishTracks();
                }
            });;

        });
    } else
        initCommunicationWithoutModule(options).then(() => { })


    if (options.modules.actionModule) {
        initActionModule(options).then(() => {
            loadActions();
            defaultResponse({ code: MSG_CODE.ACTION_ROL_HOST });
        })
    }

    window.addEventListener('focus', () => {
        FocusFound();
    });

    window.addEventListener('blur', () => {
        persistPositionAndRotationOnFocusLoss();
        navigationFocusLoss();
    });


}

function initOffScreenModules(options) {
    let initOptions = { useViewerModule: false, useNavigationModule: false };

    if (options.modules.viewerModule) {
        initOptions.useViewerModule = true;
    }

    if (options.modules.navigationModule) {
        initOptions.useNavigationModule = true;
        setupDOMNavigationModuleController(options);
    }

    initOptions.offscreenCanvasWorker = options.workers.offscreenCanvasWorker;

    registerModules(initOptions);
}

function registerModules(options) {
    let msg = {
        code: MSG_CODE.WORKER_SETUP_MODULES,
        useViewerModule: options.useViewerModule,
        useNavigationModule: options.useNavigationModule,
    };

    options.offscreenCanvasWorker.postMessage(msg);
}

export function initOffScreen(options) {
    viewerModule = options.modules.viewerModule;
    navigationModule = options.modules.navigationModule;
    setViewerModules(viewerModule, navigationModule);
    initObjectsOffscreen(options);
    setNavigationModule(navigationModule);
}

export function enableInputs(enabled) {
    // console.log('enableInputs', enabled);

    enablePlayerMovementAndRotation({ data: { enabled } }).then(() => {
        // console.log("Enable player movement and rotation");

    });
}

export function deleteSelectedSceneObject() {
    getSelectedSceneObject().then((userData) => {
        if (userData) {
            // console.log('deleteSelectedSceneObject', userData);
            deleteSceneObject({ data: userData.uuid }).then(() => {
                forceUnselectSceneObject().then();
                objectsModule.deleteSceneObject(userData.uuid).then(() => {
                    sendDeleteSceneObjectFromNetwork(userData);
                    sendCallbacks(userData.uuid, "remove");
                });
            })
        }
    });
}

function setupRenderer(options) {
    let initOptions = {
        loaderWorkerInstancer: options.workers.loaderWorkerInstancer,
        offscreenCanvasWorker: options.workers.offscreenCanvasWorker,
        specs: options.rendererOptions.specs
            ? options.rendererOptions.specs
            : getSpecs(),
        modules: options.modules,
        rendererOptions: options.rendererOptions,
        callbacks: options.callbacks,
        organizationData: options.roomOptions.organizationData
    };

    createOffscreen(initOptions);
}

function setupNavigationModule() {
    const config = viewerModule.getNavigationConfig();
    // console.log('config', config);
    initNavigationModule(config).then(() => {
        viewerModule.changeCamera(2);
    });
}

export function finishMeeting() {
    enableInputs(true);
    leaveChannel();
    disconnect();
    stopFitToObject();
}

//Manage incoming messages to Offscreen Context
export function manageWorkerMessages(msg) {
    return new Promise((resolve, reject) => {
        switch (msg.code) {
            case MSG_CODE.WORKER_INIT_OFFSCREEN:
            case MSG_CODE.WORKER_MAKE_PROXY:
            case MSG_CODE.WORKER_EVENT:
                viewerManageWorkerMessages(msg);
                resolve();
                break;

            case MSG_CODE.ASSETS_LOAD_SCENE:
                manageRendererMessages(msg).then(() => {
                    if (navigationModule) setupNavigationModule();

                    resolve();
                });
                break;

            // case MSG_CODE.SCENE_OBJECTS_CREATE:
            // case MSG_CODE.SCENE_OBJECTS_LOAD:
            // case MSG_CODE.SCENE_OBJECTS_LOAD_INDICATOR:
            //     manageObjectsMessages(msg);
            //     resolve();
            //     break;

            case MSG_CODE.VIP_GUEST_GET_REMOTE_USER:
                getSetRemoteActor(msg);
                resolve();
                break;
            case MSG_CODE.SCENE_OBJECT_DELETE:
                deleteSceneObject(msg);
                resolve();
                break;

            case MSG_CODE.NETWORKING_REMOTE_USER_JOINNED:
                remoteUserJoinned(msg);
                resolve();
                break;

            case MSG_CODE.SET_ROOM_USER_SPAWN_SETUP:
                setRemoteUserSpawnSetup(msg);
                resolve();
                break;

            case MSG_CODE.NETWORKING_REMOTE_USER_LEAVED:
                removeLinkpad(msg);
                break;

            case MSG_CODE.NETWORKING_RECEIVE_TRANSFORM_POSITION:
            case MSG_CODE.NETWORKING_RECEIVE_TRANSFORM_ROTATION:
                updateRemoteLinkpads(msg);
                resolve();
                break;

            case MSG_CODE.ROOM_USER_SPAWN_SETUP:
                spawnUser(msg.data);
                movePlayerInmediatlyTo(msg.data.data.spawnPosition);
                lookAtTargetInmediatly(msg.data.data.target);
                resolve();
                break;

            case MSG_CODE.VIEWER_SET_LIGHTS_ENABLED:
                setLightsEnabled(msg.data.value);
                resolve();
                break;
            case MSG_CODE.VIEWER_SET_HELPERS_ENABLED:
                setHelpersEnabled(msg.data.value);
                resolve();
                break;
            case MSG_CODE.VIEWER_SET_FEATURES_ENABLED:
                setFeaturesEnabled(msg.data.value);
                resolve();
                break;

            case MSG_CODE.NAVIGATION_TRANSFORM_CONTROLS_SET_MODE:
                setTransformMode(msg.data.action);
                resolve();
                break;
            case MSG_CODE.COMMUNICATION_SEND_LINKPAD_VIDEO:
                setLinkpadVideo(msg.data);
                resolve();
                break;

            case "navigation_selected_sceneObject":
                getSelectedSceneObject(msg.uuid).then();
                resolve();
                break;

            case "getCameraPosition":
                getCameraPositionAndRotation(msg).then();
                resolve();
                break;

            case MSG_CODE.COMMUNICATION_VIDEO_ENABLED:
                setVideoEnabledOnLinkpad(msg.data);
                resolve();
                break;

            case MSG_CODE.COMMUNICATION_AUDIO_ENABLED:
                setAudioEnabledOnLinkpad(msg.data);
                resolve();
                break;

            case 'createRaycast':
                getNavigationRaycast(msg.uuid).then();
                resolve();
                break;

            case 'forceDisposePreview':
                forceDisposePreview(msg).then();
                resolve();
                break;

            case 'loadGalleryLODObject':
                loadGalleryLODObject(msg).then();
                resolve();
                break;
            case 'createSceneObjectByBuffers':
                createSceneObjectByBuffers(msg).then();
                resolve();
                break;
            case 'createSceneObjectDisplay':
                createSceneObjectDisplay(msg).then();
                resolve();
                break;
            case 'placeSceneObjectDisplay':
                placeSceneObjectDisplay(msg).then();
                resolve();
                break;
            case 'loadTextureForImageSceneObject':
                loadTextureForImageSceneObject(msg).then();
                resolve();
                break;
            case 'placeImageSceneObject':
                placeImageSceneObject(msg).then();
                resolve();
                break;
            case 'createInstancedMeshLODSceneObject':
                createInstancedMeshLODSceneObject(msg).then();
                resolve();
                break;
            case 'createInstancedMeshLODSceneObjectFromDB':
                createInstancedMeshLODSceneObjectFromDB(msg).then();
                resolve();
                break;
            case 'setSceneObjectLock':
                setSceneObjectLock(msg).then();
                resolve();
                break;
            case MSG_CODE.ACTION_GET_POSITION:
                getPlayerPosition();
                resolve();
                break;
            case MSG_CODE.ACTION_MOVE_TO:
                moveToTarget(msg.position, msg.positionLook);
                resolve();
                break;
            case MSG_CODE.ACTION_LOOKTO_ORDER:
                lookAtTarget(msg.positionLook);
                break;
            case "getPlayerHeight":
                getPlayerHeight(msg);
                resolve();
                break;

            case "setEnableTransformControls":
                setEnableTransformControls(msg);
                resolve();
                break;

            case "enablePlayerMovementAndRotation":
                enablePlayerMovementAndRotation(msg);
                resolve();
                break;

            case "placeLoadingObject":
                placeLoadingObject(msg).then();
                resolve();
                break;

            case "placeSceneObject":
                placeSceneObject(msg).then();
                resolve();
                break;
            case "remoteUpdateSceneObject":
                remoteUpdateSceneObject(msg).then();
                resolve();
                break;

            case "remoteDeleteSceneObject":
                remoteDeleteSceneObject(msg).then();
                resolve();
                break;
            case "resetProjector":
                resetProjector(msg).then();
                resolve();
                break;

            case "forceUnselectSceneObject":
                forceUnselectSceneObject(msg).then();
                resolve();
                break;

            case "setVideoTextureToprojector":
                setVideoTextureToprojector(msg).then();
                resolve();
                break;

            case "setRoomElementsForOrbit":
                setRoomElementsForOrbit(msg).then();
                resolve();
                break;
            case "getLocalActor":
                getLocalActor(msg).then();
                resolve();
                break;
            case "createNotFoundObject":
                createNotFoundObject(msg).then();
                resolve();
                break;
            case MSG_CODE.ACTION_FOLLOW_USER:
                setFollow(msg);
                resolve();
                break;
            case MSG_CODE.ACTION_UNFOLLOW_USER:
                setUnFollow();
                resolve();
                break;
            case MSG_CODE.ACTION_ORBIT_OBJECT:
                actionOrbitToSceneObject(msg.uuid);
                break;
            case MSG_CODE.ACTION_ORBIT_POSITION:
                actionOrbitToPosition(msg.position);
                break;
            case 'orbitToSceneObject':
                orbitToSceneObject(msg);
                resolve();
                break;
            case 'exitOrbitMode':
                exitOrbitMode(msg);
                resolve();
                break;
            case MSG_CODE.ACTION_ROTATE_360:
                start360CameraRotation();
                resolve();
            case MSG_CODE.START_FIT_OBJECT:
                startFitToObject(msg).then();
                resolve();
                break;
            case MSG_CODE.STOP_FIT_OBJECT:
                stopFitToObject(msg).then();
                resolve();
                break;
            case MSG_CODE.REMOTE_ALL_LINKPADS:
                remoteAllLinkpads(msg).then();
                resolve();
                break;
            case MSG_CODE.NAVIGATION_FOCUS_LOSS:
                navigationFocusLoss(msg).then();
                resolve();
                break;
            case MSG_CODE.RECIVED_ACTION:
                // console.log("RECIVED_ACTION", msg);
                resolve();
                break;
            case "set_current_action":
                setCurrentAction(msg);
                resolve();
                break;
            case "inputKeyNavigation":
                inputKeyNavigation(msg);
                resolve();
                break;
            case "set_selected_users":
                setSelectedUsers(msg);
                resolve();
                break
            case MSG_CODE.ACTION_POSITION_INDICATOR:
                updateActionPointerData(msg);
                resolve();
                break;
            case MSG_CODE.ACTION_CANCEL:
                changeState(msg);
                setPointerVisibility(false);
                break;
            case "volume":
                setVolumeIndicator(msg);
                resolve();
                break;
            case "updateLinkpad":
                updateLinkpad(msg);
                resolve();
                break;
            case "getRemoteLinkpadPosition":
                getRemoteLinkpadPosition(msg);
                resolve();
                break;
            case "goToSceneObject":
                navigateToObject(msg);
                resolve();
                break;
            case MSG_CODE.SEND_MESSAGE_TO_PLAYER:
                sendMessageViaChat(msg);
                resolve();
                break;
            case "actionExitOrbit":
                actionExitOrbit();
                resolve();
                break;
            default:
                resolve();
                break;
        }
    });
}

//Manage return message to DOM
export function manageDOMWorkerResponses(msg) {
    switch (msg.code) {
        case MSG_CODE.INFO_FPS_COUNTER:
        case MSG_CODE.INFO_FPS_DRAWCALLS:
            defaultResponse(msg);
            break;
        case MSG_CODE.CORE_RESOLVE_WORKER_VIEWER_PROMISE:
            viewerWorkerPromises[msg.uuid].resolve();

            if (msg.lastCode === MSG_CODE.ASSETS_LOAD_SCENE) {
                if (objectsModule) {
                    loadSceneObjectsFromDB().then();
                }
                graphicsConnected = true;
                validateCommunicationAndNetworkingAndGraphicsConnected();

                // Load Assets finish
            }
            break;
        case MSG_CODE.CORE_RESOLVE_WORKER_OBJECTS_PROMISE:
            objectsWorkerPromises[msg.uuid].resolve();
            break;

        case MSG_CODE.SCENE_OBJECT_REGISTER_UPDATE:
            registerSceneObject(msg.data).then((sceneObject) => {
                //Seng signal to remote users, to instantiate sceneObject too
                sendOnSceneObjectCreateOrUpdate(sceneObject);
            });

            break;

        case MSG_CODE.SCENE_OBJECT_ON_SELECT:
            if (msg.data) {
                msg.data.sceneObject = getSceneObject(msg.data.uuid);
            }
            defaultResponse(msg);
            break;



        case MSG_CODE.NETWORKING_SEND_TRANSFORM_POSITION:
        case MSG_CODE.NETWORKING_SEND_TRANSFORM_ROTATION:
            sendLinkpadsTransformMessages(msg);
            break;

        case "navigationResolve":
            navigationResolve(msg);
            break;

        case "navigationReject":
            navigationReject(msg);
            break;

        case "viewerResolve":
            viewerResolve(msg);
            break;

        case "viewerReject":
            viewerReject(msg);
            break;
        /*
    case "updateLinkpadName":
        console.log("updateLinkpadName");
       // updateLinkpadNameResolve(msg);
        break;
        */
        case MSG_CODE.ACTION_RECIVE_POSITION:
            gather(msg.data.position);
            break;
        case MSG_CODE.ROOM_USER_SPAWN_DONE:
            defaultResponse(msg);
            break;
        case MSG_CODE.CHANGE_CURSOR:
            defaultResponse(msg);
            break;
        case MSG_CODE.RECIVED_ACTION:
            hostActionReciever(msg.data);
            defaultResponse(msg);
            break;
        case MSG_CODE.ACTION_POSITION_INDICATOR:
            pointerReceiver(msg.data);
            break;
        default:
            break;
    }
}

export function sendMessage(msg) {
    switch (msg.code) {
        case MSG_CODE.VIEWER_SET_LIGHTS_ENABLED:
        case MSG_CODE.VIEWER_SET_HELPERS_ENABLED:
        case MSG_CODE.VIEWER_SET_FEATURES_ENABLED:
            performUIAction(msg);
        default:
            break;
    }
}

export function getPosAndRotAngular() {
    return new Promise((resolve, reject) => {
        getCameraPositionAndRotation().then(({ position, rotation }) => {
            // console.log({ position, rotation });
            resolve({ position, rotation });
        });
    });
}

export function networkingReconnectionToMaster() {
    networkingReconnection();
}

export function editMode(enabled) {
    setEnableTransformControls({ data: { enabled } }).then(() => { });
}
export function updateMyLinkpadNameLocal(name) {
    updateMyLinkpadName(name);
}

export function updateMyLinkpadPhotoLocal(photo) {
    updateMyLinkpadPhoto(photo);
}

export function inputKey(key, type) {
    return new Promise((resolve, reject) => {
        let msg = {
            data: { key, type }
        }
        inputKeyNavigation(msg).then(({ }) => {
            // console.log({ position, rotation });
            resolve({});
        });
    });
}

function sendUpdateLinkpad(data) {
    updateLinkpad(data);
    // console.log(data.data.updateNetwork);
    if (data.data.updateNetwork) {
        sendNetworkingUpdateLinkpad(data);
    }
}

export {
    loadScene,
    MSG_CODE,
    getGalleryObjects,
    loadSceneObject,
    setDisplayToSelectedProjector,
    sceneObjectAction,
    deleteSceneObjectById,
    setTransformMode,
    enableAudio,
    enableVideo,
    enableBlurVideo,
    enableBlurVideoSettings,
    startMeeting,
    sendMessageViaChat,
    sendRoomEvent,
    updateMyActorData,
    sendPaymentStatusMessage,
    loadActions,
    switchVideoElementId,
    switchCamera,
    switchMicrophone,
    getCurrentFrameData,
    setCurrentAction,
    setSelectedUsers,
    getAssetById,
    setHost,
    changeStateActionCancel,
    setPointerVisibility,
    getIsFreeze,
    sendUpdateLinkpad,
    navigateToUser,
    goToSceneObject,
    navigateToObject,
    sendMessageToPlayer,
    forceUnselectSceneObject,
    actionExitOrbit,
    getIsMuteHost,
    sendSimpleAction,
    screenShareAction,
};
