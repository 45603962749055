import { HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { LoadingModalComponent } from '@core/components/loading-modal/loading-modal.component';
import { NavBarComponent } from '@core/components/nav-bar/nav-bar.component';
import { authInterceptor } from '@core/interceptors/auth.interceptor';
import { resellerInterceptor } from '@core/interceptors/reseller.interceptor';
import { environment } from '@environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomDatePipe } from '@shared/pipes/custom-date.pipe';

export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
  providers: [
    provideHttpClient(withFetch(), withInterceptors([authInterceptor, resellerInterceptor])),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore())
  ],
  declarations: [AppComponent],
  imports: [
    LoadingModalComponent,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NavBarComponent,
    AppRoutingModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
