// Shared Funtions

export function createMicrophoneAudioTrack(currentMinId) {
    return new Promise(resolve => {
        AgoraRTC.createMicrophoneAudioTrack({ microphoneId: currentMinId }).then(audioTrack => {
            resolve(audioTrack);
        });
    });
}

export function createCameraVideoTrack(currentCamId) {
    return new Promise((resolve, reject) => {
        console.log(currentCamId);
        var config = {
            encoderConfig: "180p_1"
        }
        if (currentCamId != null) {
            config.cameraId = currentCamId;
        }
        AgoraRTC.createCameraVideoTrack(config).then(videoTrack => {
            resolve(videoTrack);
        }).catch((err) => {
            console.log(err);
            reject("Failed to create camera video track");
        });
    });
}


export function createShareTrack(options) {
    return new Promise((resolve, reject) => {
        AgoraRTC.createScreenVideoTrack(options, "disable").then((shareVideoTrack) => {
            resolve(shareVideoTrack);
        })
    });
}