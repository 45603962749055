import { v4 as uuidv4 } from "uuid";
import {
    getSelectedSceneObject,
    getNavigationRaycast,
    forceUnselectSceneObject,
    orbitToSceneObject,
    exitOrbitMode,
} from "./navigationModuleController";
import {
    sendOnSceneObjectCreateOrUpdate,
    sendDeleteSceneObjectFromNetwork,
    sendVideoStatusToProjectorByNetwork,
    saveScreenShareInfoOnRooomProperty,
    getVideoProgressFromRoomProperty,
    saveVideoProgressInRoomProperty
} from "./networkingModuleController";
import {
    deleteSceneObject,
    loadGalleryLODObject,
    createInstancedMeshLODSceneObject,
    createInstancedMeshLODSceneObjectFromDB,
    createSceneObjectByBuffers,
    createSceneObjectDisplay,
    placeSceneObjectDisplay,
    placeImageSceneObject,
    placeLoadingObject,
    placeSceneObject,
    remoteUpdateSceneObject,
    onSceneObjectSelect,
    onSceneObjectUpdated,
    resetProjector,
    remoteDeleteSceneObject,
    setVideoTextureToprojector,
    setRoomElementsForOrbit,
    createNotFoundObject,
    loadTextureForImageSceneObject,
    setSceneObjectLock,
    forceDisposePreview,
} from "./viewerModuleController";
import { MSG_CODE } from "../constants";
import { screenShare, stopScreenShare, communicationSubscribe } from "./communicationModuleController";
import { Vector3 } from "three";
import { isIOSDevice, getSpecs } from "../utils";


let options = {};
let objectsModule;
let viewerModule;
let navigationModule;

const nearFactor = 2.0;

export let objectsWorkerPromises = {};
const sceneObjects = {};

var sceneObjectVideoTemp = undefined;
var displayTypeAction = undefined;

var currentScreenShareData = null;
let specs;

export function initObjectsModule(_options) {
    return new Promise(resolve => {
        options = _options;

        specs = options.rendererOptions.specs ? options.rendererOptions.specs : getSpecs();

        objectsModule = options.modules.objectsModule;
        viewerModule = options.modules.viewerModule;
        objectsModule.init(options, specs).then(() => {
            resolve();
        });

        communicationSubscribe("stop-screen", () => {
            stopShareNavivePopup();
        });

    });
}

export function initObjectsOffscreen(_options) {
    options = _options;
    objectsModule = options.modules.objectsModule;
    viewerModule = options.modules.viewerModule;
    navigationModule = options.modules.navigationModule;
}

export function getGalleryObjects(filter = null) {
    return objectsModule.getGalleryObjects(filter);
}

export function registerSceneObject(data) {
    return objectsModule.registerSceneObject(data);
}

// on Offscreen context
export function onSelectSceneObject(sceneObject) {
    onSceneObjectSelect(sceneObject);

    const sceneObjectData = {
        uuid: sceneObject ? sceneObject.userData.uuid : null
    }

    let msg = {
        code: MSG_CODE.SCENE_OBJECT_ON_SELECT,
        data: sceneObject ? sceneObjectData : undefined
    }

    postMessage(msg);
}

export function onSceneObjectUpdate(sceneObject) {
    onSceneObjectUpdated(sceneObject);
    let msg = {
        code: MSG_CODE.SCENE_OBJECT_REGISTER_UPDATE,
        data: {
            data: {
                position: [
                    sceneObject.position.x,
                    sceneObject.position.y,
                    sceneObject.position.z],
                rotation: [
                    sceneObject.rotation.x,
                    sceneObject.rotation.y,
                    sceneObject.rotation.z],
                scale: [
                    sceneObject.scale.x,
                    sceneObject.scale.y,
                    sceneObject.scale.z],
            },
            uuid: sceneObject.userData.uuid
        }
    }
    postMessage(msg);
}

export function getSceneObject(uuid) {
    return objectsModule.getSceneObject(uuid);
}

export function setDisplayToSelectedProjector(obj) {

    var scenObjectUuid = obj.scenObjectUuid;
    var selectedProjector = objectsModule.getSceneObject(scenObjectUuid);

    if (selectedProjector) {
        var metadata = {
            isPlaying: true,
        }

        switch (selectedProjector.typeDisplay) {
            case "video":

                removeVideo(selectedProjector);
                break;
        }
        stopScreenSharingBeforeNextAction(obj);

        switch (displayTypeAction) {
            case "video":

                metadata.assetId = sceneObjectVideoTemp.assetId;
                metadata.owner = sceneObjectVideoTemp.owner;
                metadata.ownerId = sceneObjectVideoTemp.ownerId;
                metadata.muted = false;
                metadata.loop = true;

                console.log(sceneObjectVideoTemp);
                console.log(metadata);


                sendVideoStatusToProjectorByNetwork({ uuid: selectedProjector.uuid, status: 'new', metadata })
                objectsModule.registerVideoToProyector(selectedProjector.uuid, metadata, "video").then((sceneObject) => {

                    if (sceneObject.metadata) {
                        switch (sceneObject.typeDisplay) {
                            case "video":
                                loadVideo(sceneObject);
                                break;
                        }
                    }
                    sceneObjectVideoTemp = undefined;
                });
                break;
            case "screenShare":
                objectsModule.getObjectById(selectedProjector.assetId, selectedProjector.owner, selectedProjector.ownerId).then((projectorData) => {
                    if (projectorData) {
                        var displayMaterialName = projectorData.data.displayMaterialName;
                        var uuid = selectedProjector.uuid;
                        screenShare().then((videoElement) => {
                            sendVideoTextureToProjector(videoElement, displayMaterialName, uuid, selectedProjector.assetId);
                            currentScreenShareData = {
                                uuid,
                                displayMaterialName,
                                userID: options.userOptions.userId,
                                sceneobject: selectedProjector
                            }
                            selectedProjector.metadata = metadata;
                            selectedProjector.typeDisplay = "screenShare";
                            saveScreenShareInfoOnRooomProperty({ displayMaterialName, uuid, assetId: selectedProjector.assetId, active: true });
                            sendVideoStatusToProjectorByNetwork({ uuid: selectedProjector.uuid, status: 'share', displayMaterialName, currentScreenShareData });
                        });
                    }
                });
                break;
        }
    }
}

export function screenShareAction() {
    displayTypeAction = 'screenShare';
    getSelectedSceneObject().then((data) => {
        const obj = (data != undefined) ? getSceneObject(data.uuid) : undefined;

        if (obj && obj.data.isDisplay) {
            setDisplayToSelectedProjector({ scenObjectUuid: obj.uuid });
        } else {
            var projectors = objectsModule.projectorsAvailableOnTheScene();
            var data = {
                code: "sceneProjector",
                data: projectors
            }
            options.callbacks.defaultResponse(data);
        }
    });
}
export function loadSceneObject(obj) {
    return new Promise(async (resolve, reject) => {
        const sceneObject = await objectsModule.requestSceneObject(obj.id);
        if (!sceneObject) resolve();

        // console.log(sceneObject);

        switch (sceneObject.type) {
            case 'video':
                sceneObjectVideoTemp = sceneObject;
                displayTypeAction = 'video';

                getSelectedSceneObject().then((data) => {

                    const obj = (data != undefined) ? getSceneObject(data.uuid) : undefined;

                    if (obj && obj.data.isDisplay) {
                        setDisplayToSelectedProjector({ scenObjectUuid: obj.uuid });
                    } else {
                        var projectors = objectsModule.projectorsAvailableOnTheScene();
                        var data = {
                            code: "sceneProjector",
                            data: projectors
                        }
                        options.callbacks.defaultResponse(data);
                    }
                });

                break;
            case 'image':
                //TODO: Load a plane an apply a texture to it
                loadImageSceneObject(sceneObject).then(resolve).catch(reject);
                break;
            case 'model':
                //iOS use middle LOD 
                const newSceneObject = filterSceneObjectDataUris(sceneObject);
                loadModelSceneObject(newSceneObject).then(resolve).catch(reject);
                break;
        }
    })
}

const images = {};

function loadImageSceneObject(sceneObject) {
    return new Promise((resolve, reject) => {
        const seed = uuidv4();
        loadTextureForImageSceneObject(sceneObject).then(() => {


        }).catch(e => {
            console.error(e);
        })

        getNavigationRaycast().then(intersection => {
            placeLoadingObject({ data: [{ uuid: sceneObject.uuid, position: intersection.point, normal: intersection.normal }] }).then(() => {
                var registerData = {
                    assetId: sceneObject.assetId,
                    uuid: sceneObject.uuid,
                    seed,
                    owner: sceneObject.owner,
                    ownerId: sceneObject.ownerId,
                    type: sceneObject.type,
                    data: {
                        name: sceneObject.data.name,
                        position: intersection.point,
                        rotation: [0, 0, 0],
                        normal: intersection.normal,
                        scale: [1, 1, 1],
                        isLocked: false
                    }
                }

                images[sceneObject.uuid] = registerData;

                const image = images[sceneObject.uuid];
                const message = {
                    data: {
                        uuid: image.uuid,
                        objectPosition: image.data,
                        assetId: image.assetId
                    }
                }

                placeImageSceneObject(message).then((data) => {
                    registerData.data.position = data.res.position;
                    images[sceneObject.uuid].data.position = data.res.position;

                    objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                        sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                        sendCallbacks(sceneObjectInDB, "add");
                        resolve();
                    })
                });

            }).catch(reject);
        }).catch(reject);
    })
}

function loadModelSceneObject(sceneObject) {
    return new Promise(async (resolve, reject) => {

        const seed = uuidv4();

        if (!sceneObject.data.isDisplay) {
            if (uniques[sceneObject.assetId]) {
                //cached sceneObject

                const d = {
                    uuid: sceneObject.uuid,
                    assetId: sceneObject.assetId,
                    cached: true,
                    seed,
                    specs
                }

                createInstancedMeshLODSceneObject(d).then(() => {

                }).catch(e => {
                    console.log('Error trying to create cached scene object', e);
                })

            } else {
                //object is not loaded
                loadGalleryLODObject({ uris: sceneObject.data.uris }).then((buffers) => {

                    uniques[sceneObject.assetId] = { groups: {}, assetId: sceneObject.assetId };
                    const d = {
                        uuid: sceneObject.uuid,
                        assetId: sceneObject.assetId,
                        cached: false,
                        buffers: buffers,
                        seed,
                        specs
                    }

                    createInstancedMeshLODSceneObject(d).then(() => {
                    }).catch(e => {
                        console.log('Error trying to create scene object', e);

                    })
                });
            }
        } else {
            // console.log('loadModelSceneObject Display', sceneObject);    
            loadGalleryLODObject({ uris: sceneObject.data.uris }).then((buffers) => {
                const displayMaterialName = sceneObject.data.displayMaterialName
                const message = {
                    data: {
                        uuid: sceneObject.uuid,
                        assetId: sceneObject.assetId,
                        buffers,
                        displayMaterialName,
                        specs
                    }
                }

                createSceneObjectDisplay(message).then(() => {
                    // console.log('back createSceneObjectDisplay');                    
                });

            }).catch(e => {
                console.log(e);
            });
        }

        getNavigationRaycast().then(intersection => {
            //Colocar Rotación dependiendo de la normal del raycast            
            placeLoadingObject({ data: [{ uuid: sceneObject.uuid, position: intersection.point, normal: intersection.normal }] }).then(() => {
                var registerData = {
                    assetId: sceneObject.assetId,
                    uuid: sceneObject.uuid,
                    seed,
                    owner: sceneObject.owner,
                    ownerId: sceneObject.ownerId,
                    data: {
                        name: sceneObject.data.name,
                        position: intersection.point,
                        rotation: [0, 0, 0],
                        normal: intersection.normal,
                        scale: [1, 1, 1],
                        isLocked: false
                    }
                }

                if (sceneObject.data.isDisplay) {
                    registerData.data.isDisplay = sceneObject.data.isDisplay;

                    displays[sceneObject.uuid] = registerData;

                    const display = displays[sceneObject.uuid];
                    const displayMaterialName = sceneObject.data.displayMaterialName;
                    const message = {
                        data: {
                            uuid: display.uuid,
                            objectPosition: display.data,
                            assetId: display.assetId,
                            displayMaterialName,
                        }
                    }

                    placeSceneObjectDisplay(message).then((data) => {
                        registerData.data.position = data.res.position;
                        displays[sceneObject.uuid].data.position = data.res.position;

                        objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {

                            sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                            sendCallbacks(sceneObjectInDB, "add");
                            resolve();
                        })
                    });

                } else {
                    placeSceneObject({ data: { uuid: sceneObject.uuid, assetId: sceneObject.assetId, position: intersection.point, normal: intersection.normal, seed } }).then((data) => {
                        registerData.data.position = data.res.position;
                        registerData.data.rotation = data.res.rotation;

                        objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                            sceneObjectInDB.seed = seed;
                            sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                            sendCallbacks(sceneObjectInDB, "add");
                            resolve();
                        });
                    }).catch((e) => {

                        uniques[sceneObject.assetId] = { groups: {}, assetId: sceneObject.assetId, seed };

                        addObjectPosition(sceneObject.assetId, uniques[sceneObject.assetId].groups, { position: registerData.data.position, name: registerData.data.name, rotation: [0, 0, 0], scale: registerData.data.scale, uuid: sceneObject.uuid }, seed);

                        delete uniques[sceneObject.assetId].buffers;
                        const notFound = {
                            data: uniques[sceneObject.assetId],
                        }
                        createNotFoundObject(notFound).then(() => {
                            objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                                sceneObjectInDB.status = "removed";
                                sceneObjectInDB.seed = seed;
                                sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                                sendCallbacks(sceneObjectInDB, "add");
                                // reject();
                            });

                        });
                    });
                }


            // }).catch(reject);
            }).catch();
        }).catch((e) => {
            forceDisposePreview().then();
            forceUnselectSceneObject().then();
            console.log('[REJECT]', e);
            // reject(e);
            resolve();
        });
    })
}

export function loadSceneObjectFromNetwork(sceneObject) {
    return new Promise((resolve, reject) => {
        objectsModule.requestSceneObject(sceneObject.assetId, sceneObject.owner, sceneObject.ownerId).then((ogGalleryObject) => {
            //iOS use middle LOD
            let galleryObject = filterSceneObjectDataUris(ogGalleryObject);

            if (!sceneObject.data.isDisplay) {
                if (sceneObject.type == 'image') {
                    const d = {
                        data: galleryObject.data,
                        uuid: sceneObject.uuid,
                        fromNetwork: true,
                    }
                    
                    loadTextureForImageSceneObject(d).then(() => {

                    }).catch(e => {
                        console.error(e);
                    })

                } else {
                    if (uniques[sceneObject.assetId]) {
                        //cached sceneObject
                        const d = {
                            uuid: sceneObject.uuid,
                            assetId: sceneObject.assetId,
                            cached: true,
                            seed: sceneObject.seed,
                            fromNetwork: true,
                            specs
                        }
                        createInstancedMeshLODSceneObject(d).then(() => {
                            // console.log('created Instanced Mesh LOD Scene Object');
                        }).catch((e) => {
                            console.log('error cache', e);

                        })

                    } else {
                        //object is not loaded
                        loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                            uniques[galleryObject.assetId] = { groups: {}, assetId: galleryObject.assetId };
                            const d = {
                                uuid: sceneObject.uuid,
                                assetId: sceneObject.assetId,
                                cached: false,
                                buffers: buffers,
                                seed: sceneObject.seed,
                                fromNetwork: true,
                                specs
                            }

                            createInstancedMeshLODSceneObject(d).then(() => {

                            }).catch((e) => {
                                console.log('error no cache', e);
                            });
                        }).catch(e => {
                            console.log('error', e);
                        });
                    }
                }
            }

            placeLoadingObject({ data: [{ uuid: sceneObject.uuid, position: sceneObject.data.position, rotation: sceneObject.data.rotation }] }).then(() => {
                if (sceneObject.data.isDisplay) {
                    // console.log('display ', sceneObject);

                    displays[sceneObject.uuid] = sceneObject;
                    const display = displays[sceneObject.uuid];

                    loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                        const displayMaterialName = sceneObject.data.displayMaterialName
                        const d = {
                            data: {
                                uuid: display.uuid,
                                objectPosition: display.data,
                                assetId: display.assetId,
                                buffers,
                                displayMaterialName,
                                fromNetwork: true,
                                specs
                            }
                        }

                        createSceneObjectByBuffers(d).then(() => {
                            objectsModule.remoteRegisterSceneObject(sceneObject);
                            sendCallbacks(sceneObject, "add");
                        });
                    }).catch(e => {
                        console.log(e);
                    });

                } else {
                    if (sceneObject.type == 'image') {
                        const message = {
                            data: {
                                uuid: sceneObject.uuid,
                                objectPosition: sceneObject.data,
                                assetId: sceneObject.assetId,
                                fromNetwork: true,
                            }
                        }
                        placeImageSceneObject(message).then((data) => {

                        });
                    } else {
                        const placeData = {
                            data: {
                                uuid: sceneObject.uuid,
                                assetId: sceneObject.assetId,
                                position: sceneObject.data.position,
                                rotation: sceneObject.data.rotation,
                                seed: sceneObject.seed,
                                fromNetwork: true
                            },
                        }

                        placeSceneObject(placeData).then(() => {
                            objectsModule.remoteRegisterSceneObject(sceneObject);
                            sendCallbacks(sceneObject, "add");
                            resolve();

                        }).catch((e) => {
                            uniques[sceneObject.assetId] = {
                                groups: {},
                                assetId: sceneObject.assetId,
                                seed: sceneObject.seed,
                            };

                            addObjectPosition(
                                sceneObject.assetId,
                                uniques[sceneObject.assetId].groups,
                                {
                                    position: sceneObject.data.position,
                                    name: sceneObject.data.name,
                                    rotation: sceneObject.data.rotation,
                                    scale: sceneObject.data.scale,
                                    uuid: sceneObject.uuid,
                                },
                                sceneObject.seed,
                            );

                            delete uniques[sceneObject.assetId].buffers;
                            const notFound = {
                                data: uniques[sceneObject.assetId],
                            };

                            createNotFoundObject(notFound).then(() => {
                                const sceneObjectInDB = sceneObject;
                                sceneObjectInDB.status = "removed";
                                objectsModule.remoteRegisterSceneObject(sceneObject);
                                sendCallbacks(sceneObject, "add");
                            })
                                .catch((e) => {
                                    uniques[sceneObject.assetId] = {
                                        groups: {},
                                        assetId: sceneObject.assetId,
                                        seed: sceneObject.seed,
                                    };

                                    addObjectPosition(
                                        sceneObject.assetId,
                                        uniques[sceneObject.assetId].groups,
                                        {
                                            position: sceneObject.data.position,
                                            name: sceneObject.data.name,
                                            rotation: sceneObject.data.rotation,
                                            scale: sceneObject.data.scale,
                                            uuid: sceneObject.uuid,
                                        },
                                        sceneObject.seed,
                                    );

                                    delete uniques[sceneObject.assetId].buffers;
                                    const notFound = {
                                        data: uniques[sceneObject.assetId],
                                    };
                                    createNotFoundObject(notFound).then(() => {
                                        const sceneObjectInDB = sceneObject;
                                        sceneObjectInDB.status = "removed";
                                        objectsModule.remoteRegisterSceneObject(sceneObject);
                                        sendCallbacks(sceneObjectInDB, "add");
                                        // reject();
                                    });
                                });
                        }
                        );
                    }

                }
            });

        }).catch(e => {
            console.log('catch', e);
            reject();
        })
    });
}

export function updateSceneObjectFromNetwork(sceneObject) {
    return new Promise((resolve, reject) => {
        objectsModule.remoteRegisterSceneObject(sceneObject);

        remoteUpdateSceneObject({ data: sceneObject }).then(() => {
            resolve();
        }).catch(reject);


    });
}




export async function sceneObjectAction(action, uuid) {
    const msg = {
        code: MSG_CODE.NAVIGATION_TRANSFORM_CONTROLS_SET_MODE,
        data: {
            action,
        },
    };

    switch (action) {
        case "rotate":
        case "translate":
        case "scale":
            msg.code = MSG_CODE.NAVIGATION_TRANSFORM_CONTROLS_SET_MODE;

            if (options.rendererOptions.useOffscreen) {
                options.workers.offscreenCanvasWorker.postMessage(msg);
            } else {
                // TODO: check
            }

            break;
        case "detail":
            break;
        case "delete":
            getSelectedSceneObject().then((userData) => {
                deleteSceneObject({ data: userData.uuid }).then(() => {
                    forceUnselectSceneObject().then();
                    objectsModule.deleteSceneObject(userData.uuid).then(() => {
                        sendDeleteSceneObjectFromNetwork(userData);
                        sendCallbacks(userData.uuid, "remove");
                    });
                })
            });

            break;
        case "orbit":
            getSelectedSceneObject().then((data) => {
                orbitToSceneObject({ data: { uuid: data.uuid } }).then();
                setRoomElementsForOrbit({ data: { uuid: data.uuid, isVisible: false } }).then();
            });
            break;

        case "exitOrbit":
            getSelectedSceneObject().then((data) => {
                exitOrbitMode({ data: { uuid: data.uuid } }).then();
                setRoomElementsForOrbit({ data: { uuid: data.uuid, isVisible: true } }).then();
            });
            break;

        case 'copy':
            getSelectedSceneObject().then((userData) => {
                const obj = getSceneObject(userData.uuid);
                forceUnselectSceneObject().then();
                loadSceneObject({ id: obj.assetId }).then();
            });
            break;
        case 'lock':
            // getSelectedSceneObject().then((userData) => {
            //     const obj = getSceneObject(userData.uuid);
            //     forceUnselectSceneObject().then();                
            //     setSceneObjectLock(obj).then((registerData)=>{                              
            //         objectsModule.registerSceneObject(registerData.res.data).then((sceneObjectInDB=>{
            //             console.log(sceneObjectInDB);                        
            //             sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
            //         }));
            //     });
            // });
            break;
        case 'pause':
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.isPlaying = false;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'pause' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    pauseVideo(sceneObject);
                });
            });
            break;
        case "info":
            getSelectedSceneObject().then((data) => {
                objectsModule.getObjectById(data.assetId).then(async (assets) => {
                    var response = { uuid: data.uuid };

                    if (assets.metadata?.length > 0) {
                        var metadata = JSON.parse(JSON.stringify(assets.metadata));
                        for (const data of metadata) {
                            switch (data.type) {
                                case "Video":
                                case "Image":
                                case "AR Model":
                                    var url = await objectsModule.getUrlByFileName(data.value);
                                    data.value = url;
                                    break;
                            }
                        }
                        response.metadata = metadata;
                    }
                    sendCallbacks(response, "info");
                });
            });
            break;
        case "play":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.isPlaying = true;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'play' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    playVideo(sceneObject);
                });
            });
            break;

        case "mute":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.muted = true;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'mute' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    mutedVideo(sceneObject);
                });
            });
            break;

        case "unmute":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.muted = false;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'unmute' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    mutedVideo(sceneObject);
                });
            });
            break;

        case "disableLoop":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.loop = false;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'disableLoop' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    loopVideo(sceneObject);
                });
            });
            break;

        case "enableLoop":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.loop = true;
                sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'enableLoop' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    loopVideo(sceneObject);
                });
            });
            break;

        case "stop":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                if (obj.metadata) {

                    removeVideo(obj);
                    setTimeout(() => {
                        objectsModule.getObjectById(obj.assetId, obj.owner, obj.ownerId).then((projectorData) => {
                            var displayMaterialName = projectorData.data.displayMaterialName;
                            sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'stop', displayMaterialName });
                            resetProjector({ data: { uuid: obj.uuid, displayMaterialName } }).then((sceneObject) => {
                                objectsModule.registerVideoToProyector(obj.uuid, undefined, "none").then((sceneObject) => { });
                            });
                        });

                    }, 500)
                }
            });
            break;

        case "share":
            // var obj;
            // if (uuid) {
            //     obj = getSceneObject(uuid);
            // } else {
            //     obj = await getSelectedSceneObject();

            //     obj.metadata.isPlaying = true;
            // }

            // console.log(obj);

            // console.log('%c🤪 ~ file: c:\Users\JARA\Documents\projects\linkroom_projects\node_projects\node_linkroomv3_modules\packages\interface\src\controllers\objectsModuleController.js:757 : ', 'color: #317631', obj);

            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                var metadata = {
                    isPlaying: true
                }

                objectsModule.getObjectById(obj.assetId, obj.owner, obj.ownerId).then((projectorData) => {
                    if (projectorData) {
                        stopScreenSharingBeforeNextAction(projectorData);
                        var displayMaterialName = projectorData.data.displayMaterialName;
                        var uuid = obj.uuid;
                        screenShare().then((videoElement) => {
                            sendVideoTextureToProjector(videoElement, displayMaterialName, uuid, obj.assetId);
                            currentScreenShareData = {
                                uuid,
                                displayMaterialName,
                                userID: options.userOptions.userId,
                                sceneobject: obj
                            }
                            obj.metadata = metadata;
                            obj.typeDisplay = "screenShare";
                            saveScreenShareInfoOnRooomProperty({ displayMaterialName, uuid, assetId: obj.assetId, active: true });
                            sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'share', displayMaterialName, currentScreenShareData });
                        });
                    }
                });

            });
            break;

        case "stopshare":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                stopScreenShareProjector(obj);
            });
            break;

        default:
            break;
    }
}

function stopShareNavivePopup() {
    if (currentScreenShareData) {
        const obj = currentScreenShareData.sceneobject;
        stopScreenShareProjector(obj);
    }
}

function stopScreenSharingBeforeNextAction(obj) {
    if (currentScreenShareData) {
        if (currentScreenShareData.userID == options.userOptions.userId) {
            stopScreenShare().then(() => { });
            obj.metadata = undefined;
            obj.typeDisplay = "none";
        }
    }
}

function stopScreenShareProjector(obj) {
    objectsModule.getObjectById(obj.assetId, obj.owner, obj.ownerId).then((projectorData) => {
        var displayMaterialName = projectorData.data.displayMaterialName;
        stopScreenShare().then(() => {
            resetProjector({ data: { uuid: obj.uuid, displayMaterialName } }).then((sceneObject) => { });
            currentScreenShareData = null;
            obj.metadata = undefined;
            obj.typeDisplay = "none";
            saveScreenShareInfoOnRooomProperty({ displayMaterialName, uuid: obj.uuid, assetId: obj.assetId, active: false });
            sendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'stopshare', displayMaterialName, currentScreenShareData });
        });
    });

}

export function deleteSceneObjectById(uuid) {
    const obj = getSceneObject(uuid);
    const data = {
        uuid,
        assetId: obj.assetId
    }

    deleteSceneObject({ data: uuid }).then(() => {
        forceUnselectSceneObject().then();
        objectsModule.deleteSceneObject(uuid).then(() => {
            sendDeleteSceneObjectFromNetwork(data);
            sendCallbacks(uuid, "remove");
        });
    });
}

export function deleteSceneObjectFromNetwork(data) {
    remoteDeleteSceneObject({ data }).then(() => {
        objectsModule.remoteDeleteSceneObject(data.uuid);
        sendCallbacks(data.uuid, "remove");
    });
}

export function updateVideoStatusToProjectorNetwork(data) {
    // console.log(data);
    const obj = getSceneObject(data.uuid);
    switch (data.status) {
        case "new":

            if (obj.typeDisplay == "video") {
                removeVideo(obj);
            }

            stopScreenSharingBeforeNextAction(obj);

            obj.metadata = data.metadata;
            obj.typeDisplay = "video";
            if (obj.metadata) {
                switch (obj.typeDisplay) {
                    case "video":
                        loadVideo(obj);
                        break;
                }
            }

            break;
        case 'play':
            obj.metadata.isPlaying = true;
            obj.typeDisplay = "video";
            playVideo(obj);
            break;
        case 'pause':
            obj.metadata.isPlaying = false;
            obj.typeDisplay = "video";
            pauseVideo(obj);
            break;
        case 'stop':
            removeVideo(obj);
            obj.metadata = undefined;
            obj.typeDisplay = "none";
            resetProjector({ data: { uuid: data.uuid, displayMaterialName: data.displayMaterialName } }).then();
            break;

        case "mute":
            obj.metadata.muted = true;
            mutedVideo(obj);
            break;

        case "unmute":
            obj.metadata.muted = false;
            mutedVideo(obj);
            break;

        case "disableLoop":
            obj.metadata.loop = false;
            loopVideo(obj);
            break;

        case "enableLoop":
            obj.metadata.loop = true;
            loopVideo(obj);
            break;

        case "share":
            stopScreenSharingBeforeNextAction(obj);
            obj.metadata = { isPlaying: true };
            var videoElement = document.getElementById(options.roomOptions.screenShareElementID);
            currentScreenShareData = data.currentScreenShareData;
            sendVideoTextureToProjector(videoElement, data.displayMaterialName, data.uuid, obj.assetId);
            // setTimeout(() => {
            //     var screenShareInfo = getScreenShareInfoFromRoomProperty();
            //     console.log(screenShareInfo);
            // }, 1000);
            break;
        case "stopshare":
            currentScreenShareData = data.currentScreenShareData;
            resetProjector({ data: { uuid: obj.uuid, displayMaterialName: data.displayMaterialName } }).then(() => { });
            break;
        default:
            break;
    }

}

const uniques = {};
const sceneObjectsTemp = {};
const displays = {};
let sceneObjectLoadedCount = 0;

export function loadSceneObjectsFromDB() {
    return new Promise((resolve, reject) => {

        objectsModule.getSceneObjectsFromDB().then((sceneObjectsInDB) => {
            if (Object.keys(sceneObjectsInDB).length <= 0) {
                var data = {
                    code: "finishLoadingObjectsInTheScene",
                    data: {}
                }
                options.callbacks.defaultResponse(data);
            }

            const loadingData = [];



            for (const key in sceneObjectsInDB) {
                if (Object.hasOwnProperty.call(sceneObjectsInDB, key)) {
                    const sceneObject = structuredClone(sceneObjectsInDB[key]);

                    loadingData.push({
                        uuid: sceneObject.uuid,
                        position: sceneObject.data.position,
                        rotation: sceneObject.data.rotation,
                    });

                    if (
                        !uniques[sceneObject.assetId] &&
                        !sceneObject.data.isDisplay &&
                        sceneObject.type != "image"
                    ) {
                        uniques[sceneObject.assetId] = {
                            groups: {},
                            assetId: sceneObject.assetId,
                            seed: sceneObject.seed,
                            specs,
                        };
                        sceneObjectsTemp[sceneObject.assetId] = sceneObject;
                    }

                    if (!sceneObject.data.isDisplay)
                        if (sceneObject.type == 'image') {
                            images[sceneObject.uuid] = sceneObject;
                        } else {
                            addObjectPosition(
                                sceneObject.assetId,
                                uniques[sceneObject.assetId].groups,
                                {
                                    position: sceneObject.data.position,
                                    name: sceneObject.data.name,
                                    rotation: sceneObject.data.rotation,
                                    scale: sceneObject.data.scale,
                                    uuid: sceneObject.uuid,
                                },
                                sceneObject.seed,
                                specs
                            );
                        }
                    else {
                        displays[sceneObject.uuid] = sceneObject;
                    }

                    if (sceneObject.metadata) {
                        switch (sceneObject.typeDisplay) {
                            case "video":
                                loadVideo(sceneObject);
                                break;
                        }
                    }
                }
            }

            placeLoadingObject({ data: loadingData }).then(() => {

            });


            for (const key in uniques) {
                if (Object.prototype.hasOwnProperty.call(uniques, key)) {
                    const unique = uniques[key];
                    const sceneObjectTemp = sceneObjectsTemp[key];

                    objectsModule.requestSceneObject(sceneObjectTemp.assetId, sceneObjectTemp.owner, sceneObjectTemp.ownerId).then(ogGalleryObject => {
                        //iOS use middle LOD                        
                        const galleryObject = filterSceneObjectDataUris(ogGalleryObject);

                        loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                            unique.buffers = buffers;
                            const d = {
                                data: unique,
                                buffers: buffers
                            }

                            createInstancedMeshLODSceneObjectFromDB(d).then(() => {

                                for (const key in unique.groups) {
                                    var g = unique.groups[key];
                                    g.positions.forEach(sceneObject => {
                                        storeSceneObject(true, unique.assetId, sceneObject.position, sceneObject.rotation, sceneObject.scale, sceneObject.uuid, sceneObject.name);
                                    });
                                }
                            }).catch(e => {
                                // TODO: falta colocar a función los 3 casos donde se coloca un not found
                                for (const key in unique.groups) {
                                    var g = unique.groups[key];
                                    g.positions.forEach(sceneObject => {
                                        storeSceneObject(false, unique.assetId, sceneObject.position, sceneObject.rotation, sceneObject.scale, sceneObject.uuid, sceneObject.name);
                                    });
                                }

                                delete unique.buffers;
                                const notFound = {
                                    data: unique
                                }
                                console.log('first CreateNotFoundObject');
                                createNotFoundObject(notFound).then(() => {

                                })
                            });

                        }).catch(e => {
                            //Archivo corrupto                          

                            for (const key in unique.groups) {
                                var g = unique.groups[key];
                                g.positions.forEach(sceneObject => {
                                    storeSceneObject(false, unique.assetId, sceneObject.position, sceneObject.rotation, sceneObject.scale, sceneObject.uuid, sceneObject.name);
                                });
                            }

                            delete unique.buffers;
                            const notFound = {
                                data: unique
                            }
                            console.log('second CreateNotFoundObject');
                            createNotFoundObject(notFound).then(() => {

                            })
                        })

                    }).catch(e => {

                        //Asset no existe en galería objects

                        for (const key in unique.groups) {
                            var g = unique.groups[key];
                            g.positions.forEach(sceneObject => {
                                storeSceneObject(false, unique.assetId, sceneObject.position, sceneObject.rotation, sceneObject.scale, sceneObject.uuid, sceneObject.name);
                            });
                        }
                        delete unique.buffers;
                        const notFound = {
                            data: unique
                        }
                        console.log('third CreateNotFoundObject', unique);
                        createNotFoundObject(notFound).then(() => {

                        })
                    });
                }
            }

            for (const key in displays) {
                if (Object.prototype.hasOwnProperty.call(displays, key)) {
                    const display = displays[key];
                    objectsModule.requestSceneObject(display.assetId, display.owner, display.ownerId).then(galleryObject => {

                        loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                            const displayMaterialName = galleryObject.data.displayMaterialName;
                            const d = {
                                data: {
                                    uuid: display.uuid,
                                    objectPosition: display.data,
                                    assetId: display.assetId,
                                    buffers,
                                    displayMaterialName,
                                    fromNetwork: true,
                                    specs
                                }
                            }
                            createSceneObjectByBuffers(d).then((response) => {
                                storeSceneObject(true, display.assetId, display.data.position, display.data.rotation, display.data.scale, display.uuid, display.data.name);
                            });

                        }).catch(e => {
                            console.log(e);
                            storeSceneObject(false, display.assetId, display.data.position, display.data.rotation, display.data.scale, display.uuid, display.data.name);
                        });
                    }).catch(e => {
                        console.log(e);
                        storeSceneObject(false, display.assetId, display.data.position, display.data.rotation, display.data.scale, display.uuid, display.data.name);
                    });
                }
            }

            for (const key in images) {
                if (Object.prototype.hasOwnProperty.call(images, key)) {
                    const image = images[key];
                    objectsModule.requestSceneObject(image.assetId, image.owner, image.ownerId).then(galleryObject => {
                        const d = {
                            data: galleryObject.data,
                            uuid: image.uuid

                        }
                        loadTextureForImageSceneObject(d).then(() => {
                            const message = {
                                data: {
                                    uuid: image.uuid,
                                    objectPosition: image.data,
                                    assetId: image.assetId
                                }
                            }
                            placeImageSceneObject(message).then((data) => {                                
                                // console.log(image);
                                storeSceneObject(true, image.assetId, image.data.position, image.data.rotation, image.data.scale, image.uuid, image.data.name);
                            });
                        }).catch(e => {
                            console.error(e);
                            storeSceneObject(false, image.assetId, image.data.position, image.data.rotation, image.data.scale, image.uuid, image.data.name);
                        })
                    }).catch(e => {
                        console.log(e);
                        storeSceneObject(false, image.assetId, image.data.position, image.data.rotation, image.data.scale, image.uuid, image.data.name);
                        //     // storeSceneObject(false, display.assetId, display.data.position, display.data.rotation, display.data.scale, display.uuid, display.data.name);
                    });
                }
            }

            resolve();
        }).catch(reject);
    });
}

function storeSceneObject(successfull, assetId, position, rotation, scale, uuid, name) {
    var sceneObjectInfo = {
        assetId: assetId,
        data: {
            position: position,
            rotation: rotation,
            scale: scale,
        },
        uuid: uuid
    }
    if (name) {
        sceneObjectInfo.data.name = name;
    }
    if (successfull == false) {
        sceneObjectInfo.status = "removed";
    }
    sceneObjectLoadedCount++;
    if (sceneObjectLoadedCount == objectsModule.getSceneObjectsCount()) {
        var data = {
            code: "finishLoadingObjectsInTheScene",
            data: {}
        }
        options.callbacks.defaultResponse(data);
    }
    sendCallbacks(sceneObjectInfo, "add");
}

function addObjectPosition(uuidObject, uniqueObjectGroups, objectPosition, seed = null) {
    let theGroup = null;

    for (const key in uniqueObjectGroups) {
        if (Object.prototype.hasOwnProperty.call(uniqueObjectGroups, key)) {
            const group = uniqueObjectGroups[key];
            const distance = getDistanceBetweenPoints(objectPosition.position, group.centroid);
            if (distance <= nearFactor) {
                theGroup = group;
                break;
            }
        }
    }

    if (theGroup) {
        theGroup.positions.push(objectPosition);
        theGroup.centroid = calculateCentroid(theGroup);
    } else {
        const uuid = uuidv4();
        const newGroup = {
            assetId: uuidObject,
            uuid: seed ? seed : uuid,
            positions: [objectPosition],
            centroid: objectPosition.position
        };

        if (seed)
            uniqueObjectGroups[seed] = newGroup;
        else
            uniqueObjectGroups[uuid] = newGroup;
    }

}

async function loadVideo(sceneObject) {

    var projectorData = await objectsModule.getObjectById(sceneObject.assetId, sceneObject.owner, sceneObject.ownerId);
    var videoData = await objectsModule.getObjectById(sceneObject.metadata.assetId, sceneObject.metadata.owner, sceneObject.metadata.ownerId);

    if (projectorData && videoData) {

        var displayMaterialName = projectorData.data.displayMaterialName;
        var uri = videoData.data.uris[0];
        var uuid = sceneObject.uuid;
        var container = document.getElementById(options.roomOptions.videoContainer);
        const video = document.createElement("video");
        video.src = uri;
        video.id = uuid;
        video.autoplay = true;
        video.muted = true;
        video.playsInline = true;
        video.loop = sceneObject.metadata.loop;
        video.style.width = "100px";
        video.setAttribute('crossorigin', 'anonymous');
        container.appendChild(video);
        sceneObject.metadata.muted = true;
        if (sceneObject.metadata.isPlaying) {
            var videoTime = getVideoProgressFromRoomProperty(uuid);
            if (videoTime == 0) {
                video.play();
            } else {
                video.addEventListener('loadeddata', () => {
                    videoTime = getVideoProgressFromRoomProperty(uuid);
                    video.currentTime = videoTime;
                    video.play();
                });
            }



        } else {
            var videoTime = getVideoProgressFromRoomProperty(uuid);
            video.currentTime = videoTime;
            video.pause();
        }

        sendVideoTextureToProjector(video, displayMaterialName, uuid, sceneObject.assetId);
    }
}

function playVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.play();
    }
}

function loopVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.loop = sceneObject.metadata.loop;
    }
}

function mutedVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.muted = sceneObject.metadata.muted;
    }
}

function pauseVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.pause();
    }
}

function removeVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        stopVideoTexture(sceneObject.uuid);
        videoElement.parentNode.removeChild(videoElement);
        setTimeout(() => {
            saveVideoProgressInRoomProperty();
        }, 200);

    }
}



const activeStreams = new Map();

export function sendVideoTextureToProjector(videoElement, displayMaterialName, uuid, assetId) {

    stopVideoTexture(uuid);

    let lastTime = 0;

    var specs = getSpecs();
    let fps = (specs.deviceType === "Mobile" || !specs.info.dedicated) ? 10 : 30;

    const captureFrame = (now) => {
        const timeSinceLastFrame = now - lastTime;

        if (videoElement.readyState >= videoElement.HAVE_CURRENT_DATA) {
            if (timeSinceLastFrame >= 1000 / fps) {
                try {
                    const frame = new VideoFrame(videoElement, {
                        format: "RGBA",
                        timestamp: performance.now()
                    });

                    const data = {
                        uuid,
                        displayMaterialName,
                        frame: frame,
                        assetId
                    };

                    setVideoTextureToprojector({ data }).catch((err) => {
                        console.error("Error setting video texture to projector:", err);
                    });

                    lastTime = now;
                } catch (error) {
                    console.error("Error al crear o procesar el VideoFrame:", error);
                }
            }
        }

        if (videoElement.requestVideoFrameCallback) {
            const callbackId = videoElement.requestVideoFrameCallback(captureFrame);
            if (activeStreams.get(uuid)) {
                activeStreams.get(uuid).callbackId = callbackId;
            }
        }
    };

    activeStreams.set(uuid, {
        videoElement,
        callbackId: null
    });

    captureFrame(performance.now());
}

export function stopVideoTexture(uuid) {
    if (activeStreams.has(uuid)) {
        const stream = activeStreams.get(uuid);
        if (stream.videoElement.requestVideoFrameCallback && stream.callbackId) {
            stream.videoElement.cancelVideoFrameCallback(stream.callbackId);
        }
        activeStreams.delete(uuid);
    }
}

export function stopAllVideoTextures() {
    for (const uuid of activeStreams.keys()) {
        stopVideoTexture(uuid);
    }
    activeStreams.clear();
}

function getDistanceBetweenPoints(point1, point2) {
    return Math.sqrt(
        Math.pow(point1[0] - point2[0], 2) +
        Math.pow(point1[1] - point2[1], 2) +
        Math.pow(point1[2] - point2[2], 2)
    );
}

function calculateCentroid(group) {
    const n = group.positions.length;
    const sumX = group.positions.reduce((acc, objectPosition) => acc + objectPosition.position[0], 0);
    const sumY = group.positions.reduce((acc, objectPosition) => acc + objectPosition.position[1], 0);
    const sumZ = group.positions.reduce((acc, objectPosition) => acc + objectPosition.position[2], 0);
    return [sumX / n, sumY / n, sumZ / n];
}

export function getAssetById(assetId) {
    return new Promise((resolve, reject) => {
        objectsModule.getObjectById(assetId).then((asset) => {
            const assetClone = JSON.parse(JSON.stringify(asset));
            resolve(assetClone);
        }).catch(reject);
    });
}


export function sendCallbacks(response, type) {
    switch (type) {
        case "remove":
            var data = {
                code: "RemoveSceneObjectFromDB",
                data: {
                    uuid: response,
                },
            };
            options.callbacks.defaultResponse(data);
            break;
        case "add":
            var data = {
                code: "AddSceneObjectToDB",
                data: response,
            };
            options.callbacks.defaultResponse(data);
            break;
        case "info":
            var data = {
                code: "onInfoSelectedObject",
                data: response,
            };
            options.callbacks.defaultResponse(data);
            break;
    }
}

export function navigateToObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            //DOM          
            let validate = window;
            const object1 = getSceneObject(_msg.uuid);
            let msg = {
                code: 'goToSceneObject',
                data: {
                    uuid: _msg.uuid,
                    position: object1.data.position,
                }
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {
            const posObj = new Vector3(_msg.data.position[0], _msg.data.position[1] + 0.5, _msg.data.position[2]);
            const data = {              
                    uuid: _msg.data.uuid,
                    position: posObj,
            } 
            navigationModule.checkIntersectionsAroundObject(data);
        }
    });
}

function filterSceneObjectDataUris(data) {
    let newData;
    if (isIOSDevice()) {
        newData = { ...data };
        // newData.data.uris = [data.data.uris[Math.min(1, data.data.uris.length - 1)]];
        newData.data.uris = [data.data.uris[data.data.uris.length - 1]];
        // newData.data.uris = data.data.uris.slice(1);
    } else {
        newData = data;
    }

    // console.log('Lod data: ', newData.data.name, newData.data.uris);  //TODO: Uncomment

    return newData;
}