import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { AccessRequestViewModel } from '@features/models/support-access.model';
import { Result } from '@shared/models/result.model';
import { ENDPOINTS } from '@shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class AccessRequestService {
   constructor(private http: HttpClient) {}

   list(profile: string) {
     const params = new HttpParams().set('profile', profile);
     return this.http.get<Result<AccessRequestViewModel[]>>(
       `${environment.apis.linkroom}${ENDPOINTS.accessRequests}`,
       { params }
     );
   }
 
   update(id: string, profile: string, status: 'approved' | 'denied') {
     return this.http.put<Result>(`${environment.apis.linkroom}${ENDPOINTS.accessRequests}/${id}`, {
       profile,
       status,
     });
   }
}
