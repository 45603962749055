export const MSG_CODE = {
    WORKER_SETUP_MODULES: 0,
    WORKER_INIT_OFFSCREEN: 1,
    WORKER_MAKE_PROXY: 2,
    WORKER_EVENT: 3,

    ASSETS_LOAD_ASSETS: 4,
    ASSETS_LOAD_SCENE: 5,

    INFO_FPS_COUNTER: 6,
    INFO_FPS_DRAWCALLS: 7,

    CORE_RESOLVE_WORKER_VIEWER_PROMISE: 8,
    CORE_RESOLVE_WORKER_OBJECTS_PROMISE: 9,

    VIEWER_SET_LIGHTS_ENABLED: 10,
    VIEWER_SET_HELPERS_ENABLED: 11,
    VIEWER_SET_FEATURES_ENABLED: 12,

    SCENE_OBJECTS_LOAD_INDICATOR: 13,
    SCENE_OBJECTS_CREATE: 14,
    SCENE_OBJECTS_LOAD: 15,
    SCENE_OBJECT_REGISTER_UPDATE: 16,
    SCENE_OBJECT_ON_SELECT: 17,
    SCENE_OBJECT_DELETE: 18,

    NETWORKING_LOCAL_USER_JOINNED: 19,
    NETWORKING_REMOTE_USER_JOINNED: 20,
    NETWORKING_REMOTE_USER_LEAVED: 21,
    NETWORKING_SEND_TRANSFORM_POSITION: 22,
    NETWORKING_SEND_TRANSFORM_ROTATION: 23,
    NETWORKING_RECEIVE_TRANSFORM_POSITION: 24,
    NETWORKING_RECEIVE_TRANSFORM_ROTATION: 25,
    NETWORKING_ON_USER_SPAWN: 26,
    NETWORKING_RETURN_SPAWN_TO_USER_JOINNED: 27,

    NETWORKING_ON_SCENE_OBJECTS_CREATE_OR_UPDATE: 28,
    NETWORKING_ON_SCENE_OBJECTS_DELETE: 29,
    NETWORKING_ON_SCENE_OBJECTS_CHANGE_STATE: 30,


    NAVIGATION_TRANSFORM_CONTROLS_SET_MODE: 31,

    ROOM_USER_SPAWN_SETUP: 32,

    COMMUNICATION_SEND_LINKPAD_VIDEO: 33,
    COMMUNICATION_VIDEO_ENABLED: 34,
    COMMUNICATION_AUDIO_ENABLED: 35,

    SET_ROOM_USER_SPAWN_SETUP: 36,

    NETWORKING_VIDEO_STATUS_PROJECTOR: 37,

    VIP_GUEST_PLACE_IN_POSITION_RQ: 43,
    VIP_GUEST_PLACE_IN_POSITION_RS: 44,
    VIP_GUEST_SEND_REMAINING_TIME: 45,

    VIP_GUEST_PAYMENT_PROGRESS_STATUS: 47,

    VIP_GUEST_GET_REMOTE_USER: 49,

    SEND_MESSAGE_TO_PLAYER: 81,

    START_FIT_OBJECT: 82,
    STOP_FIT_OBJECT: 83,

    NAVIGATION_FOCUS_LOSS: 84,

    REMOTE_ALL_LINKPADS: 85,

    ACTION_SEND_ORDER: 100,
    ACTION_GET_POSITION: 130,
    ACTION_RECIVE_POSITION: 131,
    ACTION_MOVE_TO: 132,
    ACTION_LOOKTO_ORDER: 133,
    ACTION_FOLLOW_ACTION: 134,
    ACTION_FOLLOW_USER: 135,
    ACTION_ORBIT_POSITION: 136,
    ACTION_ROTATE_360: 137,
    RECIVED_ACTION: 138,
    ACTION_ORBIT_OBJECT: 139,
    ACTION_POSITION_INDICATOR: 140,
    ACTION_SHOW_MODAL: 141,
    ACTION_SHOW_ALERT: 142,
    ACTION_ADD_METADATA: 143,
    ACTION_ENABLE_SINGLE_USER_EXPERIENCE: 144,
    ACTION_DISABLE_SINGLE_USER_EXPERIENCE: 145,
    ACTION_REMOVE_FROM_COMMUNICATIONS_CHANNEL: 146,
    ACTION_REMOVE_FROM_NETWORKING_ROOM: 147,
    ACTION_ROL_HOST: 148,
    ACTION_CANCEL: 149,
    ACTION_MESSAGE_POPUP: 150,
    ACTION_MUTE: 151,
    ACTION_UNMUTE: 152,
    ROOM_USER_SPAWN_DONE: 200,
    CHANGE_CURSOR: 201,
    ACTION_UNFOLLOW_USER: 202,
    ROOM_EVENT: 203,
    COMMUNICATION_MUTE_LIST: 204,
    UPDATE_LINKPAD_NAME: 205
}