import { MSG_CODE } from '../constants';
import { getSceneObject, loadSceneObjectFromNetwork, updateSceneObjectFromNetwork, updateVideoStatusToProjectorNetwork } from './objectsModuleController';

import {
    deleteSceneObjectFromNetwork
} from './objectsModuleController';
import { getCameraPositionAndRotation, getSetRemoteActor, remoteAllLinkpads, startFitToObject, updateLinkpad } from './viewerModuleController';
import { getPlayerHeight, enablePlayerMovementAndRotation } from './navigationModuleController';
import { actionHandler } from './actionModuleController';
import { JoinChannelAndPublishTracks } from "./communicationModuleController"
import { searchActorByUserId } from '../../../networking/src';
import { loadActions } from './actionModuleController';
import { ACTION_CODE } from '../../../action/src/actionConstants';

let networkingModule = null;
let networkingLocalData = {};
let options;

let roomSpawn = {};

var timeConnection;

export var myActorConnected = false;

let events = {};

export var networkingSubscribe = (_event, listener) => {
    if (!events[_event]) {
        events[_event] = [];
    }
    events[_event].push(listener);
}

let emitEvent = (_event, data) => {
    if (events[_event]) {
        events[_event].forEach((listener) => {
            listener(data);
        });
    }
}

export function initNetWorkingModule(_options) {
    return new Promise((resolve, reject) => {

        options = _options;
        networkingModule = options.modules.networkingModule;

        networkingModule.init({ apikeys: options.apikeys }).then(resolve).catch(reject);

        networkingModule.on(networkingModule.EVENT.JOINED_LOBBY, onJoinedLobby);
        networkingModule.on(networkingModule.EVENT.ACTOR_JOIN, onActorJoin);
        networkingModule.on(networkingModule.EVENT.ACTOR_LEAVE, onActorLeave);
        networkingModule.on(networkingModule.EVENT.ERROR, onNetworkError);
        networkingModule.on(networkingModule.EVENT.RECEIVE_MESSAGE, networkingReceiveMessage);
        // networkingModule.on(networkingModule.EVENT.ROOM_PROPERTIES_CHANGE, onRoomPropertiesChange); 

    });
}

export function initNetworkingWithoutModule(_options) {
    return new Promise((resolve, reject) => {
        options = _options;
        myActorConnected = true;
        emitEvent('myActorConnected', {});
        resolve();
    });
}

export function connectToRoom() {
    networkingModule.connectToMaster();
}

export function setRoomSpawn(_spawnPositions) {
    roomSpawn = _spawnPositions;
}

export function onJoinedLobby() {
    networkingModule.joinRoom(options.roomOptions.roomID);
}

function onActorJoin(actor) {
    if (actor.isLocal) {
        networkingLocalData.userId = actor.userId;
        networkingLocalData.agoraUserId = options.userOptions.userId;

        var actorData = {
            ...options.userOptions,
            userId: options.userOptions.userId,
            agoraUserId: options.userOptions.userId,
            userName: options.userOptions.userName,
            photonUserId: actor.userId,
            status: "loading",
            focus: true,
        }
        networkingModule.setActorCustomProperty(actor, "userData", actorData);

        var users = networkingModule.getRoomCustomProperty("users");
        if (users) {
            users = users.filter(user => user.agoraUserId != options.userOptions.userId);
            users.push(networkingLocalData);
        } else {
            users = [networkingLocalData];
        }
        networkingModule.setRoomCustomProperty("users", users);

        if (options.rendererOptions.useOffscreen) {
            //spawn
            // spawnnLocalUser(actor);
        } else {
            //TODO: send with out offscreen            
        }

        instanceRoomActors();

        myActorConnected = true;
        emitEvent('myActorConnected', {});

    } else {
      setTimeout(() => {
         var agoraRemoteUser = getAgoraUserIdByPhotoId(actor.userId);
         var users = networkingModule.getRoomCustomProperty("users");
         
         if (users) {
               var userFound = users.find(user => user.agoraUserId == agoraRemoteUser.agoraUserId && user.agoraUserId == options.userOptions.agoraUserId);
               if(userFound) {
                  var data = {
                     code: "onActorNewSession",
               }
               options.callbacks.defaultResponse(data);
               }
         } 
      }, 500);

      /*
      var users = networkingModule.getRoomCustomProperty("users");
      console.log(`===users====`);
      console.log(users);
      console.log(`===options.userOptions====`);
      console.log(options.userOptions);
      if (users) {
         var userFound = users.find(user => user.agoraUserId == options.userOptions.userId);
         console.log(`===userFound====`);
         console.log(userFound);
          if(userFound) {
            var data = {
               code: "onActorNewSession",
           }
           options.callbacks.defaultResponse(data);
          }
      } 
      */

      

        let msg = {
            code: MSG_CODE.NETWORKING_REMOTE_USER_JOINNED,
            data: {
                userId: actor.userId
            }
        }

        sendMessageToOffscreen(msg);
        emitEvent('newRemoteActorConnected', actor.userId);

        /*
        console.log("actorProperty",actor);
        let actorProperty = networkingModule.getActorCustomProperty(actor, "userData");

        console.log("actorProperty",actorProperty.userName);
        */
    }

    setTimeout(() => {
        previewActorsOnRoom();
    }, 1000);
}

function previewActorsOnRoom() {
    if (networkingModule == null) return;

    var actors = networkingModule.getActors();
    var actorsName = [];
    actors.forEach(actor => {
        var userData = networkingModule.getActorCustomProperty(actor, "userData");
        actorsName.push({ UserName: userData.userName ? userData.userName : "LinkRoom", userPhotonId: userData.photonUserId, userId: userData.userId, status: userData.status, focus: userData.focus });
    })
    console.table(actorsName);
}


export function getPhotonIdByAgoraUserId(agoraUserId) {
    var userFound = null
    if (networkingModule) {
        let users = networkingModule.getRoomCustomProperty("users");
        if (users) {
            userFound = users.find(user => user.agoraUserId === agoraUserId);
        }
    }
    return userFound;
}

export function getActorPropertyByUserId(userId) {
    let actor = networkingModule.searchActorByUserId(userId);
    if (actor) {
        let actorProperty = networkingModule.getActorCustomProperty(actor, "userData");
        if (actorProperty) {
            return actorProperty;
        }
    }
    return null;
}

export function getAgoraUserIdByPhotoId(photonUserId) {
    var userFound = null
    if (networkingModule) {
        let users = networkingModule.getRoomCustomProperty("users");
        if (users) {
            userFound = users.find(user => user.userId === photonUserId);
        }
    }
    return userFound;
}


function updateSpawnWhenUserLeft(actor) {
    const spawn = networkingModule.getRoomCustomProperty('spawn');
    if (spawn) {
        roomSpawn = spawn;
        leftFromSpawnPosition(roomSpawn, actor.userId);
        networkingModule.setRoomCustomProperty('spawn', roomSpawn);
    }
}


function setUserToSpawnPosition(spawn, actor) {
    return {
        userId: actor ? actor.userId : 0,
        position: actor && actor.actorNr < spawn.pos.length ? spawn.pos[actor.actorNr - 1] : spawn.pos[0],
        target: spawn.target
    };
}

function leftFromSpawnPosition(spawn, userId) {

    // for (let i = 0; i < spawn.pos.length; i++) {
    //     const pos = spawn.pos[i];
    //     if (pos.userId == userId) {
    //         pos.userId = null;
    //         break;
    //     }
    // }
}

function sendMessageToOffscreen(msg) {
    if (options.rendererOptions.useOffscreen) {
        options.workers.offscreenCanvasWorker.postMessage(msg);
    } else {
        //TODO: send with out offscreen
    }
}

function onActorLeave(actor) {
    let msg = {
        code: MSG_CODE.NETWORKING_REMOTE_USER_LEAVED,
        data: {
            userId: actor.userId
        }
    }

    updateSpawnWhenUserLeft(actor);
    sendMessageToOffscreen(msg);
    emitActorJoined(actor, false);

    verifyScreenSharingUser(actor.userId)

    setTimeout(() => {
        previewActorsOnRoom();
    }, 1000);
}

function verifyScreenSharingUser(userId) {
    var screenShareInfo = getScreenShareInfoFromRoomProperty();

    if (screenShareInfo) {
        if (screenShareInfo.active) {
            if (screenShareInfo.userId === userId) {
                screenShareInfo.active = false;
                networkingModule.setRoomCustomProperty("screenShareInfo", screenShareInfo);
                updateVideoStatusToProjectorNetwork({ uuid: screenShareInfo.uuid, status: 'stopshare', displayMaterialName: screenShareInfo.displayMaterialName, currentScreenShareData: null })
            }
        }
    }
}

export function disconnect() {
    if (networkingModule) {

        networkingModule.disconnect();
        myActorConnected = false;
    }
}

function onNetworkError(error) {
    remoteAllLinkpads();
    console.error('INTERFACE: detect network error', error);

    var data = {
        code: "OnNetworkError",
        data: error
    }
    options.callbacks.defaultResponse(data);

    // try {

    //     if(networkingModule.isJoinedToRoom())
    //         networkingModule.disconnect();

    //     connected = false;

    // } catch (error) {
    //     console.error('error trying disconnect');
    // }

    // let reconnection = networkingModule.reconnectToMaster();
}

export function networkingReconnection() {
    remoteAllLinkpads();
    let reconnection = networkingModule.reconnectToMaster();
    console.log(reconnection);

}

//Al Messages from Network is manage here
function networkingReceiveMessage(msg) {
    const { code, content, actorNr } = msg;
    let remoteActor;
    //TODO unified all message to sendMessageToOffscreen directly
    switch (code) {
        case MSG_CODE.NETWORKING_SEND_TRANSFORM_POSITION:
            sendMessageToOffscreen({ code: MSG_CODE.NETWORKING_RECEIVE_TRANSFORM_POSITION, data: content });
            break;
        case MSG_CODE.NETWORKING_SEND_TRANSFORM_ROTATION:
            sendMessageToOffscreen({ code: MSG_CODE.NETWORKING_RECEIVE_TRANSFORM_ROTATION, data: content });
            break;
        case MSG_CODE.NETWORKING_ON_USER_SPAWN:
            sendMessageToOffscreen({ code: MSG_CODE.NETWORKING_ON_USER_SPAWN, data: content.data });
            break;
        case MSG_CODE.NETWORKING_ON_SCENE_OBJECTS_CREATE_OR_UPDATE:

            const uuid = content.data.uuid;
            const sceneObject = getSceneObject(uuid);

            if (!sceneObject) {
                //create
                loadSceneObjectFromNetwork(content.data).then();
            } else {
                //update
                updateSceneObjectFromNetwork(content.data).then();
            }

            break;
        // case MSG_CODE.NETWORKING_RETURN_SPAWN_TO_USER_JOINNED:
        //     sendMessageToOffscreen({ code: MSG_CODE.NETWORKING_RETURN_SPAWN_TO_USER_JOINNED, data: content.data });
        //     break;

        case MSG_CODE.NETWORKING_ON_SCENE_OBJECTS_DELETE:
            deleteSceneObjectFromNetwork(content.data);
            break;

        case MSG_CODE.ACTION_SEND_ORDER:
            actionHandler(content);
            break;

        case MSG_CODE.ACTION_LOOKTO_ORDER:
            actionHandler(content);
            break;

        case MSG_CODE.ROOM_USER_SPAWN_SETUP:
            SetRemoteUserSpawn(content);
            saveVideoProgressInRoomProperty();
            remoteActor = networkingModule.searchActorByActorNr(actorNr);
            emitActorJoined(remoteActor, true);
            break;

        case MSG_CODE.NETWORKING_VIDEO_STATUS_PROJECTOR:
            updateVideoStatusToProjectorNetwork(content.data);
            break;

        // RESERVED_NETWORK_MESSAGES 
        case MSG_CODE.VIP_GUEST_PLACE_IN_POSITION_RQ:
            console.log(`===content====`);
            console.log(content);
            const vipCoordinates = content.hostCoordinates;
            const fanCoordinates = content.guestCoordinates;
            //MOVE TO POSITION
            remoteActor = networkingModule.searchActorByActorNr(actorNr);

            if (!vipCoordinates.position) vipCoordinates.position = [8, 1.6, 0];
            if (!vipCoordinates.rotation) vipCoordinates.rotation = [0, 90, 0];

            let userId = remoteActor.userId;

            var data = {
                code: "onStartMeeting",
                data: content.remainingTime
            }

            options.callbacks.defaultResponse(data);

            getSetRemoteActor({ data: { userId, position: vipCoordinates.position, rotation: vipCoordinates.rotation } });
            startMeeting(userId, fanCoordinates, vipCoordinates);

            break;

        case MSG_CODE.VIP_GUEST_SEND_REMAINING_TIME:
            console.log(`===content====`);
            console.log(content);

            var data = {
                code: "onExtendCallTime",
                data: content.remainingTime
            }

            options.callbacks.defaultResponse(data);
            break;

        case MSG_CODE.SEND_MESSAGE_TO_PLAYER:
            console.log(`===content====`);
            console.log(content);

            var data = {
                code: MSG_CODE.SEND_MESSAGE_TO_PLAYER,
                data: content
            }

            options.callbacks.defaultResponse(data);
            break;

        case MSG_CODE.ROOM_EVENT:
            console.log(`===content====`);
            console.log(content);

            var data = {
                code: MSG_CODE.ROOM_EVENT,
                data: content
            }

            options.callbacks.defaultResponse(data);
            break;
        case MSG_CODE.UPDATE_LINKPAD_NAME:
            console.log(content);
            updateLinkpad(content);
            break;
        default:
            break;
    }
}

export function sendNetworkingUpdateLinkpad(data) {
    // console.log(networkingModule);
    if (networkingModule == null) return;
    data.data.local = false;
    data.data.userId = networkingLocalData.userId;
    // console.log(data);
    networkingModule.sendMessage(MSG_CODE.UPDATE_LINKPAD_NAME, data);
}
// FUNCTION RESERVED_NETWORK_MESSAGES (OSU)
export function startMeeting(userId, fanPosition, vipPosition) {

    const payload =
    {
        value: ACTION_CODE.NAVIGATE_TO_POSITION,
        position: { x: fanPosition.position[0], y: fanPosition.position[1], z: fanPosition.position[2] },
        positionLook: { x: vipPosition.position[0], y: vipPosition.position[1], z: vipPosition.position[2] }
    };

    JoinChannelAndPublishTracks();
    loadActions(payload);
    enablePlayerMovementAndRotation({ data: { enabled: false } }).then(() => { });

    setTimeout(() => {
        startFitToObject({ data: { userId } });
    }, 1000);
}


export function persistPositionAndRotationOnFocusLoss() {

    if (!networkingModule || myActorConnected == false)
        return;

    getCameraPositionAndRotation().then(({ position, rotation }) => {
        const userId = networkingLocalData.userId;
        let positionConnectedActors = networkingModule.getRoomCustomProperty("positionConnectedActors") || [];
        positionConnectedActors = positionConnectedActors.filter(user => user.userId !== userId);
        positionConnectedActors.push({ userId, position, rotation });
        networkingModule.setRoomCustomProperty("positionConnectedActors", positionConnectedActors);
    });

    updateFocusedActor(false);
}

export function FocusFound() {

    if (!networkingModule)
        return;

    updateFocusedActor(true);
}

function updateFocusedActor(value) {
    var myActor = networkingModule ? networkingModule.getLocalActor() : null;
    if (myActor == null) return;

    let actorProperty = networkingModule.getActorCustomProperty(myActor, "userData");
    if (actorProperty) {
        actorProperty.focus = value;
        networkingModule.setActorCustomProperty(myActor, "userData", actorProperty);
    }
}

function SetRemoteUserSpawn(content) {

    getCameraPositionAndRotation().then(({ position, rotation }) => {
        const userId = networkingLocalData.userId;
        let positionConnectedActors = networkingModule.getRoomCustomProperty("positionConnectedActors") || [];
        positionConnectedActors = positionConnectedActors.filter(user => user.userId !== userId);
        positionConnectedActors.push({ userId, position, rotation });
        networkingModule.setRoomCustomProperty("positionConnectedActors", positionConnectedActors);

        sendMessageToOffscreen({ code: MSG_CODE.SET_ROOM_USER_SPAWN_SETUP, data: content.data });
    });

    setTimeout(() => {
        previewActorsOnRoom();
    }, 1000);
}

function instanceRoomActors() {


    const actorsInRoom = networkingModule.getRoomOtherActors();
    for (const key in actorsInRoom) {
        if (Object.prototype.hasOwnProperty.call(actorsInRoom, key)) {
            const actor = actorsInRoom[key];

            if (actor) {
                let msg = {
                    code: MSG_CODE.NETWORKING_REMOTE_USER_JOINNED,
                    data: {
                        userId: actor.userId,
                    }
                }
                sendMessageToOffscreen(msg);
            }
        }
    }
}

export function saveVideoProgressInRoomProperty() {
    if (!networkingModule)
        return;

    const videoContainer = document.getElementById(options.roomOptions.videoContainer);
    const videos = videoContainer.getElementsByTagName('video');
    const videoData = {};
    for (const video of videos) {
        const id = video.id;
        const currentTime = video.currentTime;
        const isPaused = video.paused;
        videoData[id] = {
            currentTime: currentTime,
            paused: isPaused
        };
    }
    networkingModule.setRoomCustomProperty("videoProgress", videoData);
}

export function getVideoProgressFromRoomProperty(videoId) {
    if (!networkingModule)
        return 0;
    var videoTimes = networkingModule.getRoomCustomProperty("videoProgress");
    if (videoTimes) {
        const elapsedSeconds = (Date.now() - timeConnection) / 1000;
        if (videoTimes[videoId]) {
            if (videoTimes[videoId].paused) {
                return videoTimes[videoId].currentTime
            } else {
                return videoTimes[videoId].currentTime + parseFloat(elapsedSeconds.toFixed(2) + 1);
            }
        } else {
            return 0;
        }
    } else {
        return 0;

    }
}

export function saveScreenShareInfoOnRooomProperty(data) {
    if (!networkingModule)
        return;

    var user = getPhotonIdByAgoraUserId(options.userOptions.userId);
    data.userId = user.userId;
    networkingModule.setRoomCustomProperty("screenShareInfo", data);
}

export function getScreenShareInfoFromRoomProperty() {
    if (!networkingModule)
        return;
    return networkingModule.getRoomCustomProperty("screenShareInfo");
}


export function sendLinkpadsTransformMessages(msg) {

    if (!networkingModule)
        return;

    let data = { userId: networkingLocalData.userId };

    if (msg.data.position) {
        data.position = msg.data.position;
    }

    if (msg.data.rotation) {
        data.rotation = msg.data.rotation;
    }

    networkingModule.sendMessage(msg.code, data);
}

export function sendOnSceneObjectCreateOrUpdate(data) {
    if (!networkingModule)
        return;

    const msg = {
        code: MSG_CODE.NETWORKING_ON_SCENE_OBJECTS_CREATE_OR_UPDATE,
        data
    }

    networkingModule.sendMessage(msg.code, msg);
}


export function sendDeleteSceneObjectFromNetwork(data) {
    if (!networkingModule)
        return;

    const msg = {
        code: MSG_CODE.NETWORKING_ON_SCENE_OBJECTS_DELETE,
        data: data
    }

    networkingModule.sendMessage(msg.code, msg);
}

export function sendVideoStatusToProjectorByNetwork(data) {
    if (!networkingModule)
        return;

    const msg = {
        code: MSG_CODE.NETWORKING_VIDEO_STATUS_PROJECTOR,
        data
    }

    networkingModule.sendMessage(msg.code, msg);
}

export function experienceReadyOnNetworking() {
    timeConnection = Date.now();
    var myActor = networkingModule ? networkingModule.getLocalActor() : null;
    var userSpawnPosition = setUserToSpawnPosition(roomSpawn, myActor);

    getPlayerHeight().then((playerData) => {

        userSpawnPosition.spawnPosition = userSpawnPosition.position.slice();
        userSpawnPosition.position[1] = userSpawnPosition.position[1] + playerData.height;

        let actorProperty = networkingModule.getActorCustomProperty(myActor, "userData");
        if (actorProperty) {
            actorProperty.status = "ready!";
            networkingModule.setActorCustomProperty(myActor, "userData", actorProperty);
        }

        let msg = {
            code: MSG_CODE.ROOM_USER_SPAWN_SETUP,
            data: { data: userSpawnPosition, userName: actorProperty ? actorProperty.userName : "LinkRoom", photoUrl: actorProperty ? actorProperty.photoUrl : null }
        }
        // console.log("actorProperty", actorProperty);
        sendMessageToOffscreen(msg);

        if (options.roomOptions.singleExperience == false) {
            if (myActor) {
                networkingModule.sendMessage(msg.code, msg);
                enableRemoteActors();
                emitActorJoined(myActor, true);
            }
        }
    })

    setTimeout(() => {
        previewActorsOnRoom();
    }, 1000);


}

function enableRemoteActors() {
    setTimeout(() => {
        const actorsInRoom = networkingModule.getRoomOtherActors();
        var positionConnectedActors = networkingModule.getRoomCustomProperty("positionConnectedActors");

        if (positionConnectedActors) {
            for (const key in actorsInRoom) {
                if (Object.prototype.hasOwnProperty.call(actorsInRoom, key)) {
                    const actor = actorsInRoom[key];
                    var actorFound = positionConnectedActors.find(posActor => posActor.userId == actor.userId);
                    // console.log("enableRemoteActors");
                    let actorProperty = networkingModule.getActorCustomProperty(actor, "userData");
                    //console.log("actorProperty",actorProperty);

                    let msg = {
                        code: MSG_CODE.SET_ROOM_USER_SPAWN_SETUP,
                        data: {
                            userId: actor.userId,
                            position: actorFound ? actorFound.position : [0, 1.6, 0],
                            rotation: actorFound ? actorFound.rotation : [0, 0, 0],
                            userName: actorProperty ? actorProperty.userName : "LinkRoom"
                        }
                    }
                    console.log("msg", msg);
                    sendMessageToOffscreen(msg);
                    emitActorJoined(actor, true);

                }
            }
        }
    }, 1000);
}

function emitActorJoined(actor, isJoined) {
    var userData = networkingModule.getActorCustomProperty(actor, "userData");
    if (userData) {
        var data = {
            code: isJoined ? "onActorJoined" : "onActorLeft",
            data: userData
        }
        options.callbacks.defaultResponse(data);
    }
}

export function sendMessageViaChat(data) {
    // console.log(`===data====`);
    // console.log(data);
    const { messageId, senderUserId, message, destinataryUserId } = data;
    let code = MSG_CODE.SEND_MESSAGE_TO_PLAYER;
    let actorsNr = null;

    if (destinataryUserId) {
        let destinataryUser = searchActorByUserId(destinataryUserId);
        actorsNr = [destinataryUser.actorNr];
    }

    let payload = {
        messageId,
        message,
        senderUserId
    }

    if (actorsNr) {
        networkingModule.sendMessage(code, payload, actorsNr);
    } else {
        networkingModule.sendMessage(code, payload);
    }
}

export function sendPaymentStatusMessage(msg, destinataryUserId) {
    let code = MSG_CODE.VIP_GUEST_PAYMENT_PROGRESS_STATUS;
    let actorsNr = null;

    if (destinataryUserId) {
        let userFound = searchActorByUserId(destinataryUserId);
        actorsNr = [userFound.actorNr];
    }

    if (actorsNr) {
        networkingModule.sendMessage(code, msg, actorsNr);
    } else {
        networkingModule.sendMessage(code, msg);
    }
}

export function sendRoomEvent(payload, destinataryUserId) {
    let code = MSG_CODE.ROOM_EVENT;
    let actorsNr = null;

    if (destinataryUserId) {
        let userFound = searchActorByUserId(destinataryUserId);
        actorsNr = [userFound.actorNr];
    }

    if (actorsNr) {
        networkingModule.sendMessage(code, payload, actorsNr);
    } else {
        networkingModule.sendMessage(code, payload);
    }
}

export function updateMyActorData(payload) {
    var myActor = networkingModule ? networkingModule.getLocalActor() : null;

    console.log(`===myActor====`);
    console.log(myActor);

    console.log(`===payload====`);
    console.log(payload);

    if (myActor) {
        console.log(`===myActor====`);
        console.log(myActor);
        var actorData = { ...myActor.customProperties.userData, ...payload };
        networkingModule.setActorCustomProperty(myActor, "userData", actorData);

        var data = {
            code: "onActorDataUpdated",
            data: { ...payload, userId: actorData.userId }
        }

        networkingModule.sendMessage(data.code, data.data);
        // options.callbacks.defaultResponse(data);
    }
}