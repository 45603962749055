import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate',
  standalone: true
})
export class CustomDatePipe implements PipeTransform {
   transform(value: Date | string | number, format: string = 'yyyy-MM-dd', locale: string = 'en-US'): string {
      return formatDate(value, format, locale);
    }
}
