<div id="components-pre-launch">
   <div class="row-pre-launch">
      <div class="column-pre-launch">
         <div class="row-logo-pre-launch">
            <img src="assets/images/logo.svg" alt="Linkroom" />
         </div>
         <div class="row-content-pre-launch">
            <h2>{{ title }}</h2>
            <br />
            <div>
               <button type="button" (click)="redirectToHome()">OK</button>
            </div>
            <p>
               {{ message }}
            </p>
         </div>
      </div>
   </div>
</div>